.modal-content {
  background-color: $utility25;
  border-radius: 24px;
}

.modal-header {
  padding: 20px 32px;
  border-bottom-color: $utility100;
}

.modal-body {
  padding: 20px 32px;
}

.modal-footer {
  border-top-color: $utility100;
}

@include media-breakpoint-up(sm) {
  .modal-dialog {
    width: 540px;
  }

  .modal-lg {
    width: 960px;
  }

  .modal-sm {
    width: 343px;
  }
}
