html {
  font-family: var(--sg-font-body);
}

body {
  font-family: var(--sg-font-body);
  font-size: 16px;
  line-height: 1.6;
  color: var(--sg-text-primary);
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
  font-family: var(--sg-font-heading);
  font-weight: 900;
  margin-top: 24px;
  margin-bottom: 16px;
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 16px;
}

h1,
.h1 {
  font-size: 34px;
  line-height: 1.2;
}

h2,
.h2 {
  font-size: 28px;
  line-height: 1.2;
}

h3,
.h3 {
  font-size: 24px;
  line-height: 1.4;
}

h4,
.h4 {
  font-size: 20px;
  line-height: 1.4;
}

h5,
.h5 {
  font-size: 16px;
  line-height: 1.4;
}

h6,
.h6 {
  font-size: 14px;
  line-height: 1.6;
  color: var(--sg-text-secondary);
}

.subtitle {
  font-size: 14px;
  line-height: 1.4;
  color: var(--sg-text-secondary);
  letter-spacing: 1px;
}

.body-lg,
.lead {
  font-size: 20px;
}

.body-sm {
  font-size: 14px;
}

.body-xs {
  font-size: 12px;
}

p {
  margin: 0 0 16px;
}

a {
  color: var(--sg-link);
  text-decoration: underline;

  &:focus,
  &:hover {
    color: var(--sg-link-hover);
  }

  &:active {
    color: var(--sg-link-active);
  }

  &:focus-visible {
    @include focus-state;
  }
}

@include media-breakpoint-up(sm) {
  h1,
  .h1 {
    font-size: 46px;
  }

  h2,
  .h2 {
    font-size: 34px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  .small,
  small {
    font-family: var(--sg-font-body);
    font-weight: 500;
    color: var(--sg-text-secondary);
  }
}

ul,
ol {
  padding-inline-start: 36px;
  margin-bottom: 16px;
}

li {
  margin-top: 8px;
  margin-bottom: 8px;
}

blockquote {
  border-left-color: var(--sg-hover-opacity);
}

.blockquote-reverse,
blockquote.pull-right {
  border-right-color: var(--sg-hover-opacity);
}
