.form-group {
  margin-bottom: 24px;

  label {
    margin-bottom: 8px;
    font-weight: 500;
  }
}

.form-control {
  height: 48px;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 1.4;
  color: var(--sg-text-primary);
  border: 1px solid var(--sg-form-border);
  border-radius: 4px;
  background-color: var(--sg-form-bg);
  box-shadow: none;

  &::placeholder {
    color: var(--sg-text-secondary);
  }

  &:focus {
    @include focus-state();
  }

  &:disabled,
  &[disabled] {
    background-color: var(--sg-disabled-bg);
    color: var(--sg-text-disabled);
    border-color: var(--sg-disabled-border);
  }

  &[readonly] {
    background-color: var(--sg-disabled-bg);
    border-color: var(--sg-disabled-border);
  }
}

.form-inline {
  .form-group + .form-group,
  .form-group + .btn,
  .form-group + .checkbox,
  .checkbox + .btn {
    margin-left: 20px;
  }

  .form-group label:not(.sr-only) + .form-control {
    margin-left: 6px;
  }

  input[type="checkbox"] {
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
  }
}

.input-group-addon {
  color: var(--sg-text-secondary);
  border-color: var(--sg-form-border);
  padding: 4px 16px;
  background-color: var(--sg-form-addon-bg);
}

.checkbox,
.radio {
  margin-top: 12px;
  margin-bottom: 12px;

  label {
    padding-left: 34px;
    min-height: 26px;
    font-weight: 400;
  }

  [disabled],
  &.disabled {
    label {
      color: var(--sg-text-disabled);
    }

    input[type="checkbox"],
    input[type="radio"] {
      background-color: var(--sg-disabled-bg);
      border-color: var(---sg-disabled-border);
    }
  }
}

.checkbox input[type="checkbox"],
.radio input[type="radio"],
.checkbox-inline input[type="checkbox"],
.radio-inline input[type="radio"] {
  margin-left: -34px;
}

input[type="checkbox"],
input[type="radio"] {
  margin: 2px 0 0;

  appearance: none;
  background-color: var(--sg-form-bg);
  width: 24px;
  height: 24px;
  border: 1px solid var(--sg-form-border);
  border-radius: 4px;

  display: grid;
  place-content: center;

  &::before {
    display: inline-block;
    content: "";
    width: 1.3em;
    height: 1.3em;
    transform: scale(0);
    transition: 100ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--sg-stroke-primary);
  }

  &:checked::before {
    transform: scale(1);
  }

  [disabled] &::before,
  .disabled &::before,
  [readonly] &::before,
  .readonly &::before {
    box-shadow: inset 1em 1em var(--sg-panel-border);
  }
}

input[type="checkbox"] {
  &::before {
    clip-path: polygon(23% 46%, 11% 59%, 35% 83%, 89% 30%, 77% 18%, 36% 59%);
  }

  &:focus {
    @include focus-state();
  }
}

input[type="radio"] {
  border-radius: 12px;

  &::before {
    width: 0.7em;
    height: 0.7em;
    border-radius: 0.5em;

    &::before {
      clip-path: circle(50% at 50% 50%);
    }
  }

  &:focus {
    @include focus-state();
  }
}

.checkbox-inline,
.radio-inline {
  padding-left: 34px;
}

.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
  margin-left: 20px;
}

select.form-control:not([multiple]) {
  appearance: none;
  background-color: var(--sg-form-bg);
  padding: 12px 40px 12px 16px;
  outline: none;

  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.06503 6.29038C3.94573 6.46461 4.00538 6.69691 4.12467 6.87114L9.55264 13.7822C9.79123 14.0726 10.2088 14.0726 10.4474 13.7822L15.8753 6.87114C15.9946 6.69691 16.0543 6.46461 15.935 6.29038C15.756 6.11615 15.5771 6 15.3981 6H4.54221C4.36327 6 4.18432 6.11615 4.06503 6.29038Z' fill='" + $text-primary + "'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) center;
  background-size: 20px 20px;

  [data-bs-theme="dark"] & {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.06503 6.29038C3.94573 6.46461 4.00538 6.69691 4.12467 6.87114L9.55264 13.7822C9.79123 14.0726 10.2088 14.0726 10.4474 13.7822L15.8753 6.87114C15.9946 6.69691 16.0543 6.46461 15.935 6.29038C15.756 6.11615 15.5771 6 15.3981 6H4.54221C4.36327 6 4.18432 6.11615 4.06503 6.29038Z' fill='" + $text-primary-rev + "'/%3E%3C/svg%3E%0A");
  }
}



select.form-control[disabled],
select.form-control.disabled {
  border-color: var(--sg-disabled-border);
  background-color: var(--sg-disabled-bg);

  &:not([multiple]) {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.06503 6.29038C3.94573 6.46461 4.00538 6.69691 4.12467 6.87114L9.55264 13.7822C9.79123 14.0726 10.2088 14.0726 10.4474 13.7822L15.8753 6.87114C15.9946 6.69691 16.0543 6.46461 15.935 6.29038C15.756 6.11615 15.5771 6 15.3981 6H4.54221C4.36327 6 4.18432 6.11615 4.06503 6.29038Z' fill='" + $text-disabled + "'/%3E%3C/svg%3E%0A");
  }
}

@include media-breakpoint-up(sm) {
  .form-horizontal .control-label {
    padding-top: 11px;
  }

  .form-horizontal .form-control-feedback {
    top: 6px;
  }
}

.form-control-static {
  padding-top: 11px;
  padding-bottom: 11px;
}

.form-group + .help-block {
  margin-top: -16px;
  margin-bottom: 24px;
  color: var(--sg-text-secondary);
}

.btn-group {
  .btn + .btn {
    margin-left: 0;
  }

  .btn + .btn-default {
    margin-left: -2px;
  }
}

.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .help-block \, .has-success .control-label,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label {
  color: color-mix(in srgb, $brand-success, #000 10%);
}

.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label {
  color: color-mix(in srgb, $brand-warning, #000 15%);
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
  color: $brand-danger;
}

.has-warning .form-control,
.has-warning .form-control:focus {
  border-color: $brand-warning;
}

.has-success .form-control,
.has-success .form-control:focus {
  border-color: $brand-success;
}

.has-error .form-control,
.has-error .form-control:focus {
  border-color: $brand-danger;
}

.has-feedback label ~ .form-control-feedback {
  top: 40px;

  .form-horizontal & {
    top: 6px;
  }
}

.has-success,
.has-warning,
.has-error {
  .form-control:focus {
    @include focus-state();
  }
}

.form-inline .has-feedback .form-control-feedback,
.has-feedback label.sr-only ~ .form-control-feedback {
  top: 6px;
}

.form-group-lg .form-control,
.input-lg,
select.input-lg:not([multiple]) {
  font-size: 20px;
  height: 60px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 4px;
}

.form-group-sm .form-control,
.input-sm,
select.input-sm:not([multiple]) {
  font-size: 14px;
  height: 32px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 4px;
}

.form-horizontal .form-group-lg .control-label {
  font-size: 20px;
}

.form-horizontal .form-group-sm .control-label {
  font-size: 14px;
}

@include media-breakpoint-up(sm) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 14px;
  }

  .form-horizontal .form-group-sm .control-label {
    padding-top: 5px;
  }
}

.glyphicon-ok.form-control-feedback {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM14.7 8.2L9.1 13.8C9 13.9 8.7 14 8.4 14C8.1 14 7.9 13.9 7.7 13.7L5.3 11.3C4.9 10.9 4.9 10.3 5.3 9.9C5.7 9.5 6.3 9.5 6.7 9.9L8.4 11.6L13.2 6.8C13.6 6.4 14.2 6.4 14.6 6.8C15 7.2 15.1 7.8 14.7 8.2Z' fill='%23008A21'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;

  &::before {
    content: "";
  }
}

.glyphicon-warning-sign.form-control-feedback {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.99308 0C10.7925 0 11.592 0.5 11.9917 1.3L19.6864 16.4C20.0861 17.1 20.0861 18 19.7863 18.8C19.2866 19.5 18.5871 20 17.7877 20H2.29841C1.49896 20 0.699516 19.5 0.299792 18.8C-0.0999308 18.1 -0.0999308 17.2 0.299792 16.4L7.99447 1.3C8.39419 0.5 9.19364 0 9.99308 0ZM10 14C9.4 14 9 14.4 9 15C9 15.6 9.4 16 10 16C10.6 16 11 15.6 11 15C11 14.4 10.6 14 10 14ZM10 4C9.44286 4 9.05816 4.27592 9.00605 4.69965L9 4.8V11.2C9 11.68 9.4 12 10 12C10.5571 12 10.9418 11.7241 10.994 11.3004L11 11.2V4.8C11 4.32 10.6 4 10 4Z' fill='%23EF6700'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;

  &::before {
    content: "";
  }
}

.glyphicon-remove.form-control-feedback {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0C15.5 0 20 4.5 20 10C20 15.5 15.5 20 10 20C4.5 20 0 15.5 0 10C0 4.5 4.5 0 10 0ZM10 14C9.4 14 9 14.4 9 15C9 15.6 9.4 16 10 16C10.6 16 11 15.6 11 15C11 14.4 10.6 14 10 14ZM10 4C9.44286 4 9.05816 4.27592 9.00605 4.69965L9 4.8V11.2C9 11.68 9.4 12 10 12C10.5571 12 10.9418 11.7241 10.994 11.3004L11 11.2V4.8C11 4.32 10.6 4 10 4Z' fill='%23CB374A'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;

  &::before {
    content: "";
  }
}
