@import "_breakpoints.scss";

@mixin focus-state {
  outline: 2px solid $focus-outer;
  outline-offset: 0;
  box-shadow: 0 0 0 4px $focus-inner, 0 0 0 2px $focus-outer;
}

@mixin shadow-depth-2 {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2),
    0 20px 40px 0 rgba(0, 0, 0, 0.1);
}

@mixin subtitle($size) {
  @if $size == "sm" {
    font-size: 12px;
  } @else {
    font-size: 14px;
  }

  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 500;
}

@mixin semantic-icons($type) {
  @if $type == "success" {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.89118 8.30206C2.4885 7.90531 1.84841 7.90041 1.44667 8.27652L0.308577 9.50036C-0.0990086 9.88195 -0.100861 10.4991 0.291372 10.891L4.21377 14.6945C4.61258 15.093 5.25331 15.1001 5.65956 14.725C5.65956 14.725 15.7504 4.763 15.7677 4.74269C16.081 4.37525 16.0774 3.84257 15.7566 3.48806C15.7361 3.46547 14.6315 2.26224 14.6315 2.26224C14.2373 1.90175 13.5813 1.9107 13.1888 2.30286L5.02515 10.5218L2.89118 8.30206Z' fill='white'/%3E%3C/svg%3E%0A");
  }
  @if $type == "info" {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58173 0.5 3.8147e-06 4.08172 3.8147e-06 8.5C3.8147e-06 12.9183 3.58173 16.5 8 16.5ZM9 7.5V13.5H7V7.5H9ZM9.5 4.5C9.5 5.32843 8.82843 6 8 6C7.17158 6 6.5 5.32843 6.5 4.5C6.5 3.67157 7.17158 3 8 3C8.82843 3 9.5 3.67157 9.5 4.5Z' fill='white'/%3E%3C/svg%3E%0A");
  }
  @if $type == "warning" {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.91595 1.24352L15.7985 14.7077C16.3045 15.6976 15.8263 16.5 14.7152 16.5H1.28434C0.180102 16.5 -0.306417 15.7006 0.201107 14.7077L7.08362 1.24352C7.5896 0.253664 8.40843 0.250657 8.91595 1.24352ZM6.99808 5.46844H9.00149V10.4828H6.99808V5.46844ZM6.99808 11.4857H9.00149V13.4914H6.99808V11.4857Z' fill='white'/%3E%3C/svg%3E%0A");
  }
  @if $type == "error" {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5ZM9 4.5V9.5H7V4.5H9ZM9 12.5V10.5H7V12.5H9Z' fill='white'/%3E%3C/svg%3E%0A");
  }
}

@function tint-color($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade-color($color, $percentage) {
  @return mix(black, $color, $percentage);
}

@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

@mixin body-size($size) {
  @if $size == "xs" {
    font-size: 12px;
  }
  @if $size == "sm" {
    font-size: 14px;
  }
  @if $size == "md" {
    font-size: 16px;
  }
  @if $size == "lg" {
    font-size: 20px;
  }
}


