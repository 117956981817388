.section-header {
  h2::after {
    content: "";
    display: block;
    width: 84px;
    height: 4px;
    background-color: $brand-success;
    border-radius: 2px;
    margin: 8px 0 32px;
  }
}
