// prefix for :root CSS variables
$prefix: sg-;

// colors
$black: #000000;
$white: #ffffff;
$light: #e6e6e6;
$dark: #1a1a1a;
$callout: #914bc3;

$body-bg: $white;

$brand-primary: #000000;
$brand-secondary: #00d639;
$brand-success: #00a210;
$brand-info: #0060a7;
$brand-warning: #ef6700;
$brand-danger: #cd384b;
$brand-primary-rev: #000000;

$brand-success100: #00b812;
$brand-success50: #00ad11;
$brand-info100: #005594;
$brand-info50: #005b9e;
$brand-warning100: #ff740a;
$brand-warning50: #fa6c00;
$brand-danger100: #c03042;
$brand-danger50: #c83245;

$text-primary: rgba(0, 0, 0, 0.9);
$text-secondary: rgba(0, 0, 0, 0.55);
$text-disabled: rgba(0, 0, 0, 0.3);
$text-primary-rev: rgba(255, 255, 255, 0.9);
$text-secondary-rev: rgba(255, 255, 255, 0.55);
$text-disabled-rev: rgba(255, 255, 255, 0.3);

$black10: rgba(0, 0, 0, 0.1);
$black5: rgba(0, 0, 0, 0.05);

$white20: rgba(255, 255, 255, 0.2);
$white10: rgba(255, 255, 255, 0.1);

$utility25: #f2f5f6;
$utility50: #e6ebed;
$utility75: #d9e0e4;
$utility100: #ccd6db;
$utility150: #b3c2c9;
$utility200: #99adb7;
$utility300: #668494;
$utility400: #335b70;

$grey25: #fafafa;
$grey50: #f2f2f2;
$grey100: #e6e6e6;
$grey200: #cccccc;
$grey300: #b3b3b3;
$grey400: #999999;
$grey500: #808080;
$grey600: #666666;
$grey700: #4d4d4d;
$grey800: #333333;
$grey900: #1a1a1a;
$grey950: #0d0d0d;

$jade500: #007e45;
$jade550: #007842;
$jade600: #00713e;
$jade50: #d1fde0;

$brilliant-green: $brand-secondary;
$brilliant-green50: #00e03c;
$brilliant-green100: #00eb3e;

$focus-outer: #ffaa2b;
$focus-inner: $grey900;

// scss-docs-start box-shadow-variables
$box-shadow: 0 8px 16px rgba($black, 0.15) !default;
$box-shadow-sm: 0 2px 4px rgba($black, 0.075) !default;
$box-shadow-lg: 0 16px 48px rgba($black, 0.175) !default;
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075) !default;
// scss-docs-end box-shadow-variables

$utilities-border-colors: $utility300;

$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
  lg: 1200px,
);
