.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  height: 60px;
  line-height: 1;
  font-size: 20px;
  border-radius: 4px;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  height: 32px;
  font-size: 14px;
  line-height: 1;
  border-radius: 4px;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-btn .btn + .btn {
  margin-left: -2px;
}

.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
  margin-right: -1px;
}

.input-group-btn:first-child > .btn:first-child,
.input-group-btn:first-child > .btn-group:first-child > .btn:first-child,
.input-group-btn:first-child > .dropdown-toggle:first-child {
  padding-left: 20px;
}
