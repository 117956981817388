.badge {
  @include subtitle("sm");
  font-size: 0.75em;
  border-radius: 1em;
  background-color: $grey100;
  color: $text-primary;
  padding: 0.25em 0.75em;

  .btn &,
  .list-group-item.active > &,
  .nav-pills > .active > a > & {
    color: $black;
    background-color: $grey100;
  }

  .btn-default & {
    background-color: var(--sg-hover-opacity);
  }

  a &,
  a:visited &,
  a:active & {
    color: $black;
  }
}
