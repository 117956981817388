@import "_mixin.scss";

[data-bs-theme="dark"] {
  color-scheme: dark;
}



/* clearfix */

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}



/* contextual links and levels of emphasis */

.link-warning {
  color: var(--sg-brand-warning-emphasis);
  text-decoration: underline;

  &:hover,
  &:focus {
    color: var(--sg-brand-warning-emphasis-hover);

  }
}

.link-danger {
  color: var(--sg-brand-danger-emphasis);
  text-decoration: underline;

  &:hover,
  &:focus {
    color: var(--sg-brand-danger-emphasis-hover);

  }
}

.link-subtle {
  color: inherit;
}

.link-emphasis {
  font-weight: 500;
}



/* ratios */

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}



/* position */

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@include media-breakpoint-up(xs) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@include media-breakpoint-up(sm) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@include media-breakpoint-up(md) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@include media-breakpoint-up(lg) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}



/* stacking */

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}



/* visual hide */

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}



/* make any link cover the parent with position relative */

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}



/* text truncate */

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}



/* vertical inline divider */

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}



/* vertical alignment of inline elements */

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}



/* float */

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}



/* object fit */

.object-fit-contain {
  -o-object-fit: contain !important;
  object-fit: contain !important;
}

.object-fit-cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}

.object-fit-fill {
  -o-object-fit: fill !important;
  object-fit: fill !important;
}

.object-fit-scale {
  -o-object-fit: scale-down !important;
  object-fit: scale-down !important;
}

.object-fit-none {
  -o-object-fit: none !important;
  object-fit: none !important;
}



/* opacity */

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}



/* overflows */

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}



/* display type */

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}



/* shadows */

.shadow {
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.20), 0px 20px 40px 0px rgba(0, 0, 0, 0.10);
}

.shadow-sm {
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.10) inset;
}

.shadow-lg {
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.04), 0px 50px 80px 0px rgba(0, 0, 0, 0.10);
}

.shadow-none {
  box-shadow: none !important;
}



/* div position */

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}



/* borders */

.border {
  border: 1px solid var(--sg-border-subtle);
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid var(--sg-broder-subtle) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid var(--sg-broder-subtle) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid var(--sg-broder-subtle) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid var(--sg-broder-subtle) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}



/* width and height */

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}



/* flex */

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}



/* margins */

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 4px !important;
}

.m-2 {
  margin: 8px !important;
}

.m-3 {
  margin: 16px !important;
}

.m-4 {
  margin: 24px !important;
}

.m-5 {
  margin: 48px !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.mx-2 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.mx-3 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.mx-4 {
  margin-right: 24px !important;
  margin-left: 24px !important;
}

.mx-5 {
  margin-right: 48px !important;
  margin-left: 48px !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-3 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.my-4 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.my-5 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-3 {
  margin-top: 16px !important;
}

.mt-4 {
  margin-top: 24px !important;
}

.mt-5 {
  margin-top: 48px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 4px !important;
}

.me-2 {
  margin-right: 8px !important;
}

.me-3 {
  margin-right: 16px !important;
}

.me-4 {
  margin-right: 24px !important;
}

.me-5 {
  margin-right: 48px !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mb-3 {
  margin-bottom: 16px !important;
}

.mb-4 {
  margin-bottom: 24px !important;
}

.mb-5 {
  margin-bottom: 48px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 4px !important;
}

.ms-2 {
  margin-left: 8px !important;
}

.ms-3 {
  margin-left: 16px !important;
}

.ms-4 {
  margin-left: 24px !important;
}

.ms-5 {
  margin-left: 48px !important;
}

.ms-auto {
  margin-left: auto !important;
}



/* paddings */

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 4px !important;
}

.p-2 {
  padding: 8px !important;
}

.p-3 {
  padding: 16px !important;
}

.p-4 {
  padding: 24px !important;
}

.p-5 {
  padding: 48px !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.px-2 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.px-3 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.px-4 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.px-5 {
  padding-right: 48px !important;
  padding-left: 48px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-3 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-4 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-5 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 4px !important;
}

.pt-2 {
  padding-top: 8px !important;
}

.pt-3 {
  padding-top: 16px !important;
}

.pt-4 {
  padding-top: 24px !important;
}

.pt-5 {
  padding-top: 48px !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 4px !important;
}

.pe-2 {
  padding-right: 8px !important;
}

.pe-3 {
  padding-right: 16px !important;
}

.pe-4 {
  padding-right: 24px !important;
}

.pe-5 {
  padding-right: 48px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 4px !important;
}

.pb-2 {
  padding-bottom: 8px !important;
}

.pb-3 {
  padding-bottom: 16px !important;
}

.pb-4 {
  padding-bottom: 24px !important;
}

.pb-5 {
  padding-bottom: 48px !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 4px !important;
}

.ps-2 {
  padding-left: 8px !important;
}

.ps-3 {
  padding-left: 16px !important;
}

.ps-4 {
  padding-left: 24px !important;
}

.ps-5 {
  padding-left: 48px !important;
}



/* gaps */

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 4px !important;
}

.gap-2 {
  gap: 8px !important;
}

.gap-3 {
  gap: 16px !important;
}

.gap-4 {
  gap: 24px !important;
}

.gap-5 {
  gap: 48px !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 4px !important;
}

.row-gap-2 {
  row-gap: 8px !important;
}

.row-gap-3 {
  row-gap: 16px !important;
}

.row-gap-4 {
  row-gap: 24px !important;
}

.row-gap-5 {
  row-gap: 48px !important;
}

.column-gap-0 {
  -moz-column-gap: 0 !important;
  column-gap: 0 !important;
}

.column-gap-1 {
  -moz-column-gap: 4px !important;
  column-gap: 4px !important;
}

.column-gap-2 {
  -moz-column-gap: 8px !important;
  column-gap: 8px !important;
}

.column-gap-3 {
  -moz-column-gap: 16px !important;
  column-gap: 16px !important;
}

.column-gap-4 {
  -moz-column-gap: 24px !important;
  column-gap: 24px !important;
}

.column-gap-5 {
  -moz-column-gap: 48px !important;
  column-gap: 48px !important;
}



/* font sizes */

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}



/* line height */

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}



/* other type helpers */

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-opacity-25 {
  opacity: 0.25;
}

.text-opacity-50 {
  opacity: 0.5;
}

.text-opacity-75 {
  opacity: 0.75;
}

.text-opacity-100 {
  opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}



/* user selection and cursor */

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}



/* border radius */

.rounded {
  border-radius: var(--sg-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--sg-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--sg-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--sg-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--sg-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--sg-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}



/* z-index and visibility */

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@include media-breakpoint-up(sm) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 4px !important;
  }

  .m-sm-2 {
    margin: 8px !important;
  }

  .m-sm-3 {
    margin: 16px !important;
  }

  .m-sm-4 {
    margin: 24px !important;
  }

  .m-sm-5 {
    margin: 48px !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }

  .mx-sm-2 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }

  .mx-sm-3 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }

  .mx-sm-4 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }

  .mx-sm-5 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .my-sm-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .my-sm-3 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .my-sm-4 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .my-sm-5 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 4px !important;
  }

  .mt-sm-2 {
    margin-top: 8px !important;
  }

  .mt-sm-3 {
    margin-top: 16px !important;
  }

  .mt-sm-4 {
    margin-top: 24px !important;
  }

  .mt-sm-5 {
    margin-top: 48px !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 4px !important;
  }

  .me-sm-2 {
    margin-right: 8px !important;
  }

  .me-sm-3 {
    margin-right: 16px !important;
  }

  .me-sm-4 {
    margin-right: 24px !important;
  }

  .me-sm-5 {
    margin-right: 48px !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 4px !important;
  }

  .mb-sm-2 {
    margin-bottom: 8px !important;
  }

  .mb-sm-3 {
    margin-bottom: 16px !important;
  }

  .mb-sm-4 {
    margin-bottom: 24px !important;
  }

  .mb-sm-5 {
    margin-bottom: 48px !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 4px !important;
  }

  .ms-sm-2 {
    margin-left: 8px !important;
  }

  .ms-sm-3 {
    margin-left: 16px !important;
  }

  .ms-sm-4 {
    margin-left: 24px !important;
  }

  .ms-sm-5 {
    margin-left: 48px !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 4px !important;
  }

  .p-sm-2 {
    padding: 8px !important;
  }

  .p-sm-3 {
    padding: 16px !important;
  }

  .p-sm-4 {
    padding: 24px !important;
  }

  .p-sm-5 {
    padding: 48px !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }

  .px-sm-2 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }

  .px-sm-3 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }

  .px-sm-4 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }

  .px-sm-5 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .py-sm-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .py-sm-3 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .py-sm-4 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .py-sm-5 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 4px !important;
  }

  .pt-sm-2 {
    padding-top: 8px !important;
  }

  .pt-sm-3 {
    padding-top: 16px !important;
  }

  .pt-sm-4 {
    padding-top: 24px !important;
  }

  .pt-sm-5 {
    padding-top: 48px !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 4px !important;
  }

  .pe-sm-2 {
    padding-right: 8px !important;
  }

  .pe-sm-3 {
    padding-right: 16px !important;
  }

  .pe-sm-4 {
    padding-right: 24px !important;
  }

  .pe-sm-5 {
    padding-right: 48px !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 4px !important;
  }

  .pb-sm-2 {
    padding-bottom: 8px !important;
  }

  .pb-sm-3 {
    padding-bottom: 16px !important;
  }

  .pb-sm-4 {
    padding-bottom: 24px !important;
  }

  .pb-sm-5 {
    padding-bottom: 48px !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 4px !important;
  }

  .ps-sm-2 {
    padding-left: 8px !important;
  }

  .ps-sm-3 {
    padding-left: 16px !important;
  }

  .ps-sm-4 {
    padding-left: 24px !important;
  }

  .ps-sm-5 {
    padding-left: 48px !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@include media-breakpoint-up(md) {
  .m-md-0 {
    margin: 0 !important;
  }
  
  .m-md-1 {
    margin: 4px !important;
  }
  
  .m-md-2 {
    margin: 8px !important;
  }
  
  .m-md-3 {
    margin: 16px !important;
  }
  
  .m-md-4 {
    margin: 24px !important;
  }
  
  .m-md-5 {
    margin: 48px !important;
  }
  
  .m-md-auto {
    margin: auto !important;
  }
  
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  
  .mx-md-1 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  
  .mx-md-2 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
  
  .mx-md-3 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
  
  .mx-md-4 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
  
  .mx-md-5 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }
  
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  
  .my-md-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  
  .my-md-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  
  .my-md-3 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  
  .my-md-4 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  
  .my-md-5 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  
  .mt-md-0 {
    margin-top: 0 !important;
  }
  
  .mt-md-1 {
    margin-top: 4px !important;
  }
  
  .mt-md-2 {
    margin-top: 8px !important;
  }
  
  .mt-md-3 {
    margin-top: 16px !important;
  }
  
  .mt-md-4 {
    margin-top: 24px !important;
  }
  
  .mt-md-5 {
    margin-top: 48px !important;
  }
  
  .mt-md-auto {
    margin-top: auto !important;
  }
  
  .me-md-0 {
    margin-right: 0 !important;
  }
  
  .me-md-1 {
    margin-right: 4px !important;
  }
  
  .me-md-2 {
    margin-right: 8px !important;
  }
  
  .me-md-3 {
    margin-right: 16px !important;
  }
  
  .me-md-4 {
    margin-right: 24px !important;
  }
  
  .me-md-5 {
    margin-right: 48px !important;
  }
  
  .me-md-auto {
    margin-right: auto !important;
  }
  
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  
  .mb-md-1 {
    margin-bottom: 4px !important;
  }
  
  .mb-md-2 {
    margin-bottom: 8px !important;
  }
  
  .mb-md-3 {
    margin-bottom: 16px !important;
  }
  
  .mb-md-4 {
    margin-bottom: 24px !important;
  }
  
  .mb-md-5 {
    margin-bottom: 48px !important;
  }
  
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  
  .ms-md-0 {
    margin-left: 0 !important;
  }
  
  .ms-md-1 {
    margin-left: 4px !important;
  }
  
  .ms-md-2 {
    margin-left: 8px !important;
  }
  
  .ms-md-3 {
    margin-left: 16px !important;
  }
  
  .ms-md-4 {
    margin-left: 24px !important;
  }
  
  .ms-md-5 {
    margin-left: 48px !important;
  }
  
  .ms-md-auto {
    margin-left: auto !important;
  }
  
  .p-md-0 {
    padding: 0 !important;
  }
  
  .p-md-1 {
    padding: 4px !important;
  }
  
  .p-md-2 {
    padding: 8px !important;
  }
  
  .p-md-3 {
    padding: 16px !important;
  }
  
  .p-md-4 {
    padding: 24px !important;
  }
  
  .p-md-5 {
    padding: 48px !important;
  }
  
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  
  .px-md-1 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
  
  .px-md-2 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  
  .px-md-3 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
  
  .px-md-4 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
  
  .px-md-5 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }
  
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  
  .py-md-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  
  .py-md-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  
  .py-md-3 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  
  .py-md-4 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  
  .py-md-5 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  
  .pt-md-0 {
    padding-top: 0 !important;
  }
  
  .pt-md-1 {
    padding-top: 4px !important;
  }
  
  .pt-md-2 {
    padding-top: 8px !important;
  }
  
  .pt-md-3 {
    padding-top: 16px !important;
  }
  
  .pt-md-4 {
    padding-top: 24px !important;
  }
  
  .pt-md-5 {
    padding-top: 48px !important;
  }
  
  .pe-md-0 {
    padding-right: 0 !important;
  }
  
  .pe-md-1 {
    padding-right: 4px !important;
  }
  
  .pe-md-2 {
    padding-right: 8px !important;
  }
  
  .pe-md-3 {
    padding-right: 16px !important;
  }
  
  .pe-md-4 {
    padding-right: 24px !important;
  }
  
  .pe-md-5 {
    padding-right: 48px !important;
  }
  
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  
  .pb-md-1 {
    padding-bottom: 4px !important;
  }
  
  .pb-md-2 {
    padding-bottom: 8px !important;
  }
  
  .pb-md-3 {
    padding-bottom: 16px !important;
  }
  
  .pb-md-4 {
    padding-bottom: 24px !important;
  }
  
  .pb-md-5 {
    padding-bottom: 48px !important;
  }
  
  .ps-md-0 {
    padding-left: 0 !important;
  }
  
  .ps-md-1 {
    padding-left: 4px !important;
  }
  
  .ps-md-2 {
    padding-left: 8px !important;
  }
  
  .ps-md-3 {
    padding-left: 16px !important;
  }
  
  .ps-md-4 {
    padding-left: 24px !important;
  }
  
  .ps-md-5 {
    padding-left: 48px !important;
  }
  
  .text-md-start {
    text-align: left !important;
  }
  
  .text-md-end {
    text-align: right !important;
  }
  
  .text-md-center {
    text-align: center !important;
  }
}

@include media-breakpoint-up(lg) {
  .m-lg-0 {
    margin: 0 !important;
  }
  
  .m-lg-1 {
    margin: 4px !important;
  }
  
  .m-lg-2 {
    margin: 8px !important;
  }
  
  .m-lg-3 {
    margin: 16px !important;
  }
  
  .m-lg-4 {
    margin: 24px !important;
  }
  
  .m-lg-5 {
    margin: 48px !important;
  }
  
  .m-lg-auto {
    margin: auto !important;
  }
  
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  
  .mx-lg-1 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  .mx-lg-2 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
  
  .mx-lg-3 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
  
  .mx-lg-4 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
  
  .mx-lg-5 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }
  
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  
  .my-lg-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  
  .my-lg-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  
  .my-lg-3 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  
  .my-lg-4 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  
  .my-lg-5 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  
  .mt-lg-1 {
    margin-top: 4px !important;
  }
  
  .mt-lg-2 {
    margin-top: 8px !important;
  }
  
  .mt-lg-3 {
    margin-top: 16px !important;
  }
  
  .mt-lg-4 {
    margin-top: 24px !important;
  }
  
  .mt-lg-5 {
    margin-top: 48px !important;
  }
  
  .mt-lg-auto {
    margin-top: auto !important;
  }
  
  .me-lg-0 {
    margin-right: 0 !important;
  }
  
  .me-lg-1 {
    margin-right: 4px !important;
  }
  
  .me-lg-2 {
    margin-right: 8px !important;
  }
  
  .me-lg-3 {
    margin-right: 16px !important;
  }
  
  .me-lg-4 {
    margin-right: 24px !important;
  }
  
  .me-lg-5 {
    margin-right: 48px !important;
  }
  
  .me-lg-auto {
    margin-right: auto !important;
  }
  
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  
  .mb-lg-1 {
    margin-bottom: 4px !important;
  }
  
  .mb-lg-2 {
    margin-bottom: 8px !important;
  }
  
  .mb-lg-3 {
    margin-bottom: 16px !important;
  }
  
  .mb-lg-4 {
    margin-bottom: 24px !important;
  }
  
  .mb-lg-5 {
    margin-bottom: 48px !important;
  }
  
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  
  .ms-lg-1 {
    margin-left: 4px !important;
  }
  
  .ms-lg-2 {
    margin-left: 8px !important;
  }
  
  .ms-lg-3 {
    margin-left: 16px !important;
  }
  
  .ms-lg-4 {
    margin-left: 24px !important;
  }

  .ms-lg-5 {
    margin-left: 48px !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 4px !important;
  }

  .p-lg-2 {
    padding: 8px !important;
  }

  .p-lg-3 {
    padding: 16px !important;
  }

  .p-lg-4 {
    padding: 24px !important;
  }

  .p-lg-5 {
    padding: 48px !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }

  .px-lg-2 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }

  .px-lg-3 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }

  .px-lg-4 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }

  .px-lg-5 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .py-lg-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .py-lg-3 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .py-lg-4 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .py-lg-5 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  
  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 4px !important;
  }

  .pt-lg-2 {
    padding-top: 8px !important;
  }

  .pt-lg-3 {
    padding-top: 16px !important;
  }

  .pt-lg-4 {
    padding-top: 24px !important;
  }

  .pt-lg-5 {
    padding-top: 48px !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 4px !important;
  }

  .pe-lg-2 {
    padding-right: 8px !important;
  }

  .pe-lg-3 {
    padding-right: 16px !important;
  }

  .pe-lg-4 {
    padding-right: 24px !important;
  }

  .pe-lg-5 {
    padding-right: 48px !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 4px !important;
  }

  .pb-lg-2 {
    padding-bottom: 8px !important;
  }

  .pb-lg-3 {
    padding-bottom: 16px !important;
  }

  .pb-lg-4 {
    padding-bottom: 24px !important;
  }

  .pb-lg-5 {
    padding-bottom: 48px !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 4px !important;
  }

  .ps-lg-2 {
    padding-left: 8px !important;
  }

  .ps-lg-3 {
    padding-left: 16px !important;
  }

  .ps-lg-4 {
    padding-left: 24px !important;
  }

  .ps-lg-5 {
    padding-left: 48px !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}


@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}



/* responsive margin and padding utilities */

.margin-y-sm {
  margin-top: 32px;
  margin-bottom: 32px;

  @include media-breakpoint-up(sm) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.margin-y-md {
  margin-top: 40px;
  margin-bottom: 40px;

  @include media-breakpoint-up(sm) {
    margin-top: 64px;
    margin-bottom: 64px;
  }
}

.margin-y-lg {
  margin-top: 64px;
  margin-bottom: 64px;

  @include media-breakpoint-up(sm) {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

.margin-b-none {
  margin-bottom: 0 !important;
}

.margin-t-none {
  margin-top: 0 !important;
}

.padding-xxs {
  padding: 16px;
}

.padding-xs {
  padding: 24px;
}

.padding-sm {
  padding: 24px;

  @include media-breakpoint-up(sm) {
    padding: 32px;
  }
}

.padding-md {
  padding: 24px;

  @include media-breakpoint-up(sm) {
    padding: 40px;
  }
}

.padding-lg {
  padding: 24px;

  @include media-breakpoint-up(sm) {
    padding: 64px;
  }
}

.padding-1-lg {
  padding: 24px;

  @include media-breakpoint-up(sm) {
    padding: 64px;
  }

  @include media-breakpoint-up(md) {
    padding: 64px 64px 64px 0;
  }
}

.padding-y-xs {
  padding-top: 16px;
  padding-bottom: 16px;
}

.padding-y-sm {
  padding-top: 24px;
  padding-bottom: 24px;
}

.padding-y-md {
  padding-top: 32px;
  padding-bottom: 32px;

  @include media-breakpoint-up(sm) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.padding-y-lg {
  padding-top: 40px;
  padding-bottom: 40px;

  @include media-breakpoint-up(md) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.padding-y-xl {
  padding-top: 64px;
  padding-bottom: 64px;

  @include media-breakpoint-up(sm) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.padding-t-none {
  padding-top: 0;
}

.padding-b-none {
  padding-bottom: 0;
}



/* responsive corner radius */

.corner-xs {
  border-radius: 16px;
}

.corner-sm {
  border-radius: 24px;
}

.corner-md {
  border-radius: 24px;

  @include media-breakpoint-up(sm) {
    border-radius: 32px;
  }
}

.corner-lg {
  border-radius: 32px;

  @include media-breakpoint-up(sm) {
    border-radius: 40px;
  }
}

.corner-1-sm {
  border-radius: 24px;

  @include media-breakpoint-up(md) {
    border-radius: 0 0 24px 24px;
  }
}

.corner-1-md {
  border-radius: 24px;

  @include media-breakpoint-up(sm) {
    border-radius: 32px;
  }
  @include media-breakpoint-up(md) {
    border-radius: 0 0 32px 32px;
  }
}

.corner-1-lg {
  border-radius: 24px;

  @include media-breakpoint-up(sm) {
    border-radius: 64px;
  }
  @include media-breakpoint-up(md) {
    border-radius: 0 0 64px 64px;
  }
}



/* set of background colors */

.bg-body,
.bg-white {
  background-color: #ffffff;
}

.bg-light-grey {
  background-color: #{$utility25};
}

.bg-black4 {
  background-color: rgba(0, 0, 0, 0.04);
}

.bg-dark-grey {
  background-color: #{$grey900};
}

.bg-black95 {
  background-color: rgba(0, 0, 0, 0.95);
}

.bg-black {
  background-color: #000000;
}

.bg-subtle {
  background-color: var(--sg-bg-subtle);
}

.bg-bold {
  background-color: var(--sg-bg-bold);
}

.bg-standard {
  background-color: var(--sg-bg);
}

.bg-rev {
  background-color: var(--sg-bg-rev);
}

.bg-promo {
  background-color: #{$jade50};
}

.text-emphasis {
  color: var(--sg-text-emphasis);
}



/* text utilities */

.body-sm {
  @include body-size(sm);
}

.body-xs {
  @include body-size(xs);
}

.body-md {
  @include body-size(md);
}

.body-lg {
  @include body-size(lg);
}

.text-muted {
  color: var(--sg-text-secondary);
}

.text-primary {
  color: var(--sg-text-primary);
  font-weight: 500;
}

.text-success {
  color: var(--sg-brand-success-emphasis);
}

.text-info {
  color: $brand-info;
}

.text-warning {
  color: var(--sg-brand-warning-emphasis);
}

.text-danger {
  color: $brand-danger;
}

.bg-primary {
  color: var(--sg-text-primary-rev);
  background-color: var(--sg-stroke-primary);
}

.bg-success {
  background-color: var(--sg-brand-success-subtle);
}

.bg-info {
  background-color: var(--sg-brand-info-subtle);
}

.bg-warning {
  background-color: var(--sg-brand-warning-subtle);
}

.bg-danger {
  background-color: var(--sg-brand-danger-subtle);
}
