@import "_variables.scss";

:root,
[data-bs-theme = "light"] {
  --sg-font-heading: "Sage Headline", "Helvetica Neue", Roboto, "Segoe UI",
    Arial;
  --sg-font-body: "Sage Text", "Helvetica Neue", Roboto, "Segoe UI", Arial;

  --sg-brand-accent:      #{$brand-success};
  --sg-brand-accent-rev:  #{$brand-secondary};

  --sg-hover:         #e6e6e6;
  --sg-hover-rev:     #333333;
  --sg-hover-opacity: rgba(0, 0, 0, 0.1);
  --sg-active:        #f2f2f2;
  --sg-active-rev:    #191919;
  --sg-active-opacity: rgba(0, 0, 0, 0.05);
  --sg-active-primary:  #{$brand-primary};

  --sg-stroke-primary:      #000000;
  --sg-stroke-primary-rev:  #ffffff;

  --sg-text-primary:    rgba(0, 0, 0, 0.9);
  --sg-text-secondary:  rgba(0, 0, 0, 0.55);
  --sg-text-disabled:   rgba(0, 0, 0, 0.3);

  --sg-text-primary-rev:    rgba(255, 255, 255, 0.9);
  --sg-text-secondaryy-rev: rgba(255, 255, 255, 0.55);
  --sg-text-disabledy-rev:  rgba(255, 255, 255, 0.3);

  --sg-text-emphasis: #{$brand-success};

  --sg-brand-primary-subtle:  color-mix(in srgb, #{$brand-primary}, #fff 92%);
  --sg-brand-success-subtle:  color-mix(in srgb, #{$brand-success}, #fff 92%);
  --sg-brand-info-subtle:     color-mix(in srgb, #{$brand-info}, #fff 92%);
  --sg-brand-warning-subtle:  color-mix(in srgb, #{$brand-warning}, #fff 92%);
  --sg-brand-danger-subtle:   color-mix(in srgb, #{$brand-danger}, #fff 92%);

  --sg-brand-primary-subtle-hover:  color-mix(in srgb, #{$brand-primary}, #fff 94%);
  --sg-brand-success-subtle-hover:  color-mix(in srgb, #{$brand-success}, #fff 94%);
  --sg-brand-info-subtle-hover:     color-mix(in srgb, #{$brand-info}, #fff 94%);
  --sg-brand-warning-subtle-hover:  color-mix(in srgb, #{$brand-warning}, #fff 94%);
  --sg-brand-danger-subtle-hover:   color-mix(in srgb, #{$brand-danger}, #fff 94%);

  --sg-brand-primary-emphasis:  color-mix(in srgb, #{$brand-primary}, #fff 8%);
  --sg-brand-success-emphasis:  color-mix(in srgb, #{$brand-success}, #000 20%);
  --sg-brand-info-emphasis:     color-mix(in srgb, #{$brand-info}, #000 20%);
  --sg-brand-warning-emphasis:  color-mix(in srgb, #{$brand-warning}, #000 20%);
  --sg-brand-danger-emphasis:   color-mix(in srgb, #{$brand-danger}, #000 20%);

  --sg-brand-primary-emphasis-hover:  color-mix(in srgb, #{$brand-primary}, #fff 4%);
  --sg-brand-success-emphasis-hover:  color-mix(in srgb, #{$brand-success}, #000 30%);
  --sg-brand-info-emphasis-hover:     color-mix(in srgb, #{$brand-info}, #000 30%);
  --sg-brand-warning-emphasis-hover:  color-mix(in srgb, #{$brand-warning}, #000 30%);
  --sg-brand-danger-emphasis-hover:   color-mix(in srgb, #{$brand-danger}, #000 30%);

  --sg-link:        #{$jade500};
  --sg-link-hover:  #{$jade600};
  --sg-link-active: #{$jade550};

  --sg-form-bg:       #ffffff;
  --sg-form-border:     #{$utility300};
  --sg-disabled-bg:     #{$utility50};
  --sg-disabled-border: #{$utility100};
  --sg-form-addon-bg:   #{$grey100};

  --sg-border-subtle: #{$grey200};

  --sg-bg-subtle:               #{$grey50};
  --sg-bg-subtle-hover:         color-mix(in srgb, var(--sg-bg-subtle), #fff 8%);
  --sg-bg-grey50:             #{$grey50};
  --sg-bg-grey50-hover:         color-mix(in srgb, var(--sg-bg-grey50), #fff 8%);
  --sg-bg-bold:                 #{$grey900};
  --sg-bg-bold-hover:           color-mix(in srgb, var(--sg-bg-bold), #fff 8%);
  --sg-bg-light-grey:           #{$utility25};
  --sg-bg-light-grey-hover:     color-mix(in srgb, var(--sg-bg-light-grey), #000 8%);
  --sg-bg:                    #ffffff;
  --sg-bg-hover:                #{$grey50};
  --sg-bg-rev:                #000000;
  --sg-bg-rev-hover:            #{$grey950};
  --sg-bg-light-opacity:      rgba(0,0,0,.04);
  --sg-bg-light-opacity-hover:rgba(0,0,0,.06);

  --sg-border-radiu:      32px;
  --sg-border-radius-xs:  16px;
  --sg-border-radius-sm:  24px;
  --sg-border-radius-lg:  40px;
  --sg-border-radius-xl:  64px;
  --sg-border-radius-xxl: 88px;
}

[data-bs-theme="dark"] {
  --sg-brand-accent:      #{$brand-secondary};
  --sg-brand-accent-rev:  #{$brand-success};

  --sg-hover:         #333333;
  --sg-hover-rev:     #e6e6e6;
  --sg-hover-opacity: #{$white20};
  --sg-active:        #191919;
  --sg-active-rev:    #f2f2f2;
  --sg-active-opacity:  #{$white10};
  --sg-active-primary:  #{$brand-secondary};

  --sg-stroke-primary: #ffffff;
  --sg-stroke-primary-rev: #000000;

  --sg-text-primary:    #{$text-primary-rev};
  --sg-text-secondary:  #{$text-secondary-rev};
  --sg-text-disabled:   #{$text-disabled-rev};

  --sg-text-primary-rev:    #{$text-primary};
  --sg-text-secondary-rev:  #{$text-secondary};
  --sg-text-disabled-rev:   #{$text-disabled};

  --sg-text-emphasis: #{$brand-secondary};

  --sg-brand-primary-subtle:  color-mix(in srgb, #{$brand-primary}, #fff 8%);
  --sg-brand-success-subtle:  color-mix(in srgb, #{$brand-success}, #000 92%);
  --sg-brand-info-subtle:     color-mix(in srgb, #{$brand-info}, #000 92%);
  --sg-brand-warning-subtle:  color-mix(in srgb, #{$brand-warning}, #000 92%);
  --sg-brand-danger-subtle:   color-mix(in srgb, #{$brand-danger}, #000 92%);

  --sg-brand-primary-subtle-hover:  color-mix(in srgb, #{$brand-primary}, #fff 4%);
  --sg-brand-success-subtle-hover:  color-mix(in srgb, #{$brand-success}, #000 96%);
  --sg-brand-info-subtle-hover:     color-mix(in srgb, #{$brand-info}, #000 96%);
  --sg-brand-warning-subtle-hover:  color-mix(in srgb, #{$brand-warning}, #000 96%);
  --sg-brand-danger-subtle-hover:   color-mix(in srgb, #{$brand-danger}, #000 96%);

  --sg-brand-primary-emphasis:  #{$brand-secondary};
  --sg-brand-success-emphasis:  color-mix(in srgb, #{$brand-success}, #fff 20%);
  --sg-brand-info-emphasis:     color-mix(in srgb, #{$brand-info}, #fff 20%);
  --sg-brand-warning-emphasis:  color-mix(in srgb, #{$brand-warning}, #fff 20%);
  --sg-brand-danger-emphasis:   color-mix(in srgb, #{$brand-danger}, #fff 20%);

  --sg-brand-primary-emphasis-hover:  #{$brand-secondary};
  --sg-brand-success-emphasis-hover:  color-mix(in srgb, #{$brand-success}, #fff 30%);
  --sg-brand-info-emphasis-hover:     color-mix(in srgb, #{$brand-info}, #fff 30%);
  --sg-brand-warning-emphasis-hover:  color-mix(in srgb, #{$brand-warning}, #fff 30%);
  --sg-brand-danger-emphasis-hover:   color-mix(in srgb, #{$brand-danger}, #fff 30%);

  --sg-link:        #{$brilliant-green};
  --sg-link-hover:  #{$brilliant-green50};
  --sg-link-active: #{$brilliant-green100};

  --sg-form-bg:         #{$grey950};
  --sg-form-border:     #{$utility200};
  --sg-disabled-bg:     #{$grey800};
  --sg-disabled-border: #{$grey600};
  --sg-form-addon-bg:   #{$grey800};

  --sg-border-subtle: #{$grey700};

  --sg-bg-subtle:               #{$grey950};
  --sg-bg-subtle-hover:         color-mix(in srgb, var(--sg-bg-subtle), #fff 8%);
  --sg-bg-grey50:               #{$grey50};
  --sg-bg-grey50-hover:         color-mix(in srgb, var(--sg-bg-grey50), #fff 8%);
  --sg-bg-bold:                 #{$grey50};
  --sg-bg-bold-hover:           color-mix(in srgb, var(--sg-bg-bold), #fff 8%);
  --sg-bg-light-grey:           #{$utility25};
  --sg-bg-light-grey-hover:     color-mix(in srgb, var(--sg-bg-light-grey), #000 8%);
  --sg-bg:                    #000000;
  --sg-bg-hover:                #{$grey950};
  --sg-bg-rev:                #ffffff;
  --sg-bg-rev-hover:            #{$grey50};
  --sg-bg-light-opacity:      rgba(255,255,255,.06);
  --sg-bg-light-opacity-hover:rgba(255,255,255,.08);
}
