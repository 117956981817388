.list-group-item {
  margin-top: 0;
  padding: 10px 16px;
  border-color: var(--sg-border-subtle);
  background-color: var(--sg-bg);

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  > .badge {
    margin: 4px 0;
  }

  &.active,
  &.active:focus,
  &.active:hover {
    color: var(--sg-text-primary-rev);
    background-color: var(--sg-stroke-primary);
    border-color: var(--sg-stroke-primary);

    .list-group-item-text {
      color: var(--sg-text-primary-rev);
    }
  }

  &.disabled,
  &.disabled:focus,
  &.disabled:hover {
    color: var(--sg-text-disabled);
    background-color: var(--sg-disabled-bg);
  }
}

a.list-group-item,
button.list-group-item {
  color: var(--sg-text-primary);

  &:focus,
  &:hover {
    color: var(--sg-text-primary);
    background-color: var(--sg-hover-opacity);
  }

  .list-group-item-heading {
    color: var(--sg-text-primary);
  }
}

.list-group > a {
  text-decoration: none;
}

.list-group-item-success,
a.list-group-item-success,
button.list-group-item-success {
  background-color: var(--sg-brand-success-subtle);
  color: var(--sg-brand-success-emphasis);
}

.list-group-item-info,
a.list-group-item-info,
button.list-group-item-info {
  background-color: var(--sg-brand-info-subtle);
  color: var(--sg-brand-info-emphasis);
}

.list-group-item-warning,
a.list-group-item-warning,
button.list-group-item-warning {
  background-color: var(--sg-brand-warning-subtle);
  color: var(--sg-brand-warning-emphasis);
}

.list-group-item-danger,
a.list-group-item-danger,
button.list-group-item-danger {
  background-color: var(--sg-brand-danger-subtle);
  color: var(--sg-brand-danger-emphasis);
}

a.list-group-item-success,
button.list-group-item-success {
  &:focus,
  &:hover {
    color: var(--sg-brand-success-emphasis-hover);
    background-color: var(--sg-brand-success-subtle-hover);
  }
}

a.list-group-item-info,
button.list-group-item-info {
  &:focus,
  &:hover {
    color: var(--sg-brand-info-emphasis-hover);
    background-color: var(--sg-brand-info-subtle-hover);
  }
}

a.list-group-item-warning,
button.list-group-item-warning {
  &:focus,
  &:hover {
    color: var(--sg-brand-warning-emphasis-hover);
    background-color: var(--sg-brand-warning-subtle-hover);
  }
}

a.list-group-item-danger,
button.list-group-item-danger {
  &:focus,
  &:hover {
    color: var(--sg-brand-danger-emphasis-hover);
    background-color: var(--sg-brand-danger-subtle-hover);
  }
}
