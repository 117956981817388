.btn-group .btn {
  border-radius: 24px;
  line-height: 1;
}

.btn-group .btn-lg {
  border-radius: 30px;
}

.btn-group-lg .btn {
  border-radius: 30px;
}

.btn-group-sm .btn {
  border-radius: 16px;
}

.btn-group-xs .btn {
  border-radius: 14px;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  padding-left: 20px;
}

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  padding-right: 20px;
}

.btn-group > .btn:only-child {
  padding-left: 20px;
  padding-right: 20px;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -2px;
}

.btn-group-vertical .btn + .btn {
  margin-left: 0;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -2px;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.btn-group-justified {
  display: flex;
  flex-direction: row;

  > .btn,
  > .btn-group {
    display: block;
    flex-grow: 1;
  }

  > .btn-group {
    display: flex;

    .btn {
      flex-grow: 1;
    }
  }
}

.btn-primary .caret,
.btn-info .caret,
.btn-danger .caret {
  filter: invert(100%);
}


[data-bs-theme = "dark"] {
  .btn-primary .caret,
  .btn-success .caret,
  .btn-warning .caret {
    filter: none;
  }

}


.btn-group .btn + .dropdown-toggle {
  position: relative;

  &::before {
    content: "";
    border-left: 1px solid $white;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  &.btn-success,
  &.btn-warning {
    &::before {
      border-left-color: $black;
    }
  }

  &.btn-default {
    &::before {
      border-left-color: transparent;
    }
  }

  [data-bs-theme = "dark"] &.btn-primary {
    &::before {
      border-left-color: $black;
    }
  }
}

.btn-group.open .dropdown-toggle {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25) inset;
}
