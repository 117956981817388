.carousel-control {
  width: 10%;
}

.carousel-control .glyphicon-chevron-left {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.150002 14.4C0 14.7 0 15.15 0.150002 15.6C0.300001 15.75 0.299999 15.9 0.449999 16.05L10.95 26.55C11.55 27.15 12.45 27.15 13.05 26.55C13.65 25.95 13.65 25.05 13.05 24.45L5.1 16.5L28.5 16.5C29.4 16.5 30 15.9 30 15C30 14.1 29.4 13.5 28.5 13.5L5.1 13.5L12.9 5.7C13.5 5.1 13.5 4.2 12.9 3.6C12.6 3.3 12.15 3.15 11.85 3.15C11.55 3.15 11.1 3.3 10.8 3.6L0.449999 13.95C0.299999 14.1 0.150002 14.25 0.150002 14.4Z' fill='white'/%3E%3C/svg%3E%0A");
  margin-left: -15px;

  &::before {
    content: "";
  }
}

.carousel-control .glyphicon-chevron-right {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M29.85 15.6C30 15.3 30 14.85 29.85 14.4C29.7 14.25 29.7 14.1 29.55 13.95L19.05 3.45C18.45 2.85 17.55 2.85 16.95 3.45C16.35 4.05 16.35 4.95 16.95 5.55L24.9 13.5H1.5C0.6 13.5 0 14.1 0 15C0 15.9 0.6 16.5 1.5 16.5H24.9L17.1 24.3C16.5 24.9 16.5 25.8 17.1 26.4C17.4 26.7 17.85 26.85 18.15 26.85C18.45 26.85 18.9 26.7 19.2 26.4L29.55 16.05C29.7 15.9 29.85 15.75 29.85 15.6Z' fill='white'/%3E%3C/svg%3E%0A");
  margin-right: -15px;

  &::before {
    content: "";
  }
}

.carousel-indicators {
  bottom: 8px;

  li {
    width: 8px;
    height: 8px;
    background-color: $grey500;
    border: none;
    margin: 0;

    + li,
    + li.active {
      margin-left: 8px;
    }
  }

  li.active {
    width: 16px;
    height: 8px;
    background-color: var(--sg-text-emphasis);
  }
}
