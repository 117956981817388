.progress {
  background-color: var(--sg-hover-opacity);
  box-shadow: unset;
  border-radius: 10px;
}

.progress-bar {
  background-color: var(--sg-brand-accent);
  color: $black;
}

.progress-bar-success {
  background-color: $brand-success;
}

.progress-bar-info {
  background-color: $brand-info;
  color: $white;
}

.progress-bar-warning {
  background-color: $brand-warning;
}

.progress-bar-danger {
  background-color: $brand-danger;
  color: $white;
}
