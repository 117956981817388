.asset-card {
  position: relative;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--sg-text-primary);
  transition: all 200ms ease-in-out;

  .card-heading {
    display: flex;
    flex-direction: column;

    h4,
    .h4,
    h5,
    .h5 {
      order: 2;
      font-size: 16px;
    }
  }

  .card-body {
    flex-grow: 4;
  }

  .type {
    order: 1;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 1px;
    font-weight: 500;
    text-transform: uppercase;
    margin-left: -24px;
    position: relative;
    padding: 5px 0 0 64px;

    &::before {
      content: "";
      position: absolute;
      width: 48px;
      height: 28px;
      border-radius: 4px;
      background-color: #e96d68;
      top: 0;
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0503 0V5.8C10.0503 7 11.0566 8 12.1635 8H18V17.8C18 19 16.9937 20 15.7862 20H4.21384C3.00629 20 2 19 2 17.7V2.2C2 1 3.00629 0 4.21384 0H10.0503ZM9.24528 8.6C8.64151 8.6 8.03774 9.1 8.03774 9.8C8.03774 10.6 8.44025 11.6 9.04403 12.6C8.9434 13 8.74214 13.5 8.64151 13.6C7.33333 14.1 6.42767 14.7 5.92453 15.1C5.62264 15.3 5.52201 15.7 5.52201 16C5.52201 16.4 5.72327 16.7 5.92453 16.9C6.22641 17.2 6.5283 17.3 6.83019 17.3C7.13208 17.3 7.43396 17.2 7.73585 17C8.13836 16.7 8.84277 15.7 9.44654 14.5L10.6541 14.2C11.4591 14.9 12.3648 15.4 13.3711 15.4C13.9748 15.4 14.5786 14.9 14.5786 14.2C14.5786 13.6 13.9748 13 13.1698 13.1C12.3648 13.1 11.5597 13.2 10.7547 13.3C10.4528 13.1 10.2516 12.8 10.0503 12.5C10.3522 11.6 10.4528 10.7 10.4528 9.8C10.4528 9.2 9.84906 8.6 9.24528 8.6ZM7.8 15.3C7.5 15.9 7.2 16.3 7 16.4C6.9 16.5 6.8 16.5 6.8 16.5L6.7504 16.4992C6.696 16.496 6.68 16.48 6.6 16.4C6.5 16.3 6.5 16.2 6.5 16.2L6.5008 16.1896C6.504 16.164 6.52 16.1 6.6 16.1C6.8 15.9 7.2 15.6 7.8 15.3ZM13.3 14C13.4 14 13.5 14.1 13.5 14.2C13.5 14.4 13.4 14.5 13.3 14.5C12.8 14.5 12.4 14.4 11.9 14.1C12.4 14 12.8 14 13.3 14ZM9.2 9.5C9.4 9.5 9.5 9.6 9.5 9.8C9.5 10.3 9.5 10.7 9.4 11.2C9.1 10.7 9 10.2 9 9.8C9 9.6 9.1 9.5 9.2 9.5ZM11 0L18 7H11.9C11.4 7 11 6.6 11 6.1V0Z' fill='white'/%3E%3C/svg%3E%0A");
      background-position: 24px center;
      background-repeat: no-repeat;
    }
  }

  .card-footer {
    margin-top: 16px;

    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-indent: -2000px;
      border-radius: 20px;
    }
  }

  .date {
    font-size: 12px;
    color: var(--sg-text-secondary);
  }

  &.word {
    .type::before {
      background-color: #347bbb;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.894 0H4.19905C2.99957 0 2 1 2 2.2V17.7C2 19 2.99957 20 4.19905 20H15.794C16.9935 20 17.9931 19 17.9931 17.8V2.2C18.0931 1 17.0935 0 15.894 0ZM14.0722 16H6.92783C6.37113 16 6 15.6 6 15C6 14.4 6.37113 14 6.92783 14H14.0722C14.6289 14 15 14.4 15 15C15 15.6 14.6289 16 14.0722 16ZM14.0722 13H6.92783C6.37113 13 6 12.6 6 12C6 11.4 6.37113 11 6.92783 11H14.0722C14.6289 11 15 11.4 15 12C15 12.6 14.6289 13 14.0722 13ZM14.0722 10H6.92783C6.37113 10 6 9.6 6 9C6 8.4 6.37113 8 6.92783 8H14.0722C14.6289 8 15 8.4 15 9C15 9.6 14.6289 10 14.0722 10ZM13.9474 6H10.0526C9.42105 6 9 5.6 9 5C9 4.4 9.52632 4 10.0526 4H13.9474C14.5789 4 15 4.4 15 5C15 5.6 14.5789 6 13.9474 6Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }

  &.image {
    .type::before {
      background-color: $brand-success;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.50432 12.8148L7.6 12.9L9.6 15L4.5 20H4.2C3.05217 20 2.08733 19.0851 2.00561 17.9553L2 17.8V17.1L6.2 12.9C6.56923 12.5308 7.10888 12.5024 7.50432 12.8148ZM12.9349 15.8066L13 15.9L16.8 19.7C16.5429 19.8714 16.2122 19.9694 15.8711 19.9939L15.7 20H7.4L11.6 15.8C11.9733 15.4267 12.608 15.4018 12.9349 15.8066ZM10 0V5.8C10 6.94783 10.9149 7.91267 12.0447 7.99439L12.2 8H18V17.9L14.5 14.4C13.6529 13.5529 12.5401 13.326 11.4951 13.6357L11.3 13.7L9 11.4C7.944 10.344 6.05856 10.3018 4.9362 11.2733L4.8 11.4L2 14.2V2.2C2 1.05217 2.91493 0.0873346 4.04468 0.00560533L4.2 0H10ZM16 9C15.4 9 15 9.4 15 10C15 10.6 15.4 11 16 11C16.6 11 17 10.6 17 10C17 9.4 16.6 9 16 9ZM11 0L18 7H11.9C11.4385 7 11.0621 6.65917 11.007 6.21347L11 6.1V0Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }

  &.sheet {
    .type::before {
      background-color: #5ebd89;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.25 0C3.0075 0 2 1.0075 2 2.25V17.75C2 18.9925 3.0075 20 4.25 20H15.75C16.9925 20 18 18.9925 18 17.75V8H12.25C11.0075 8 10 6.9925 10 5.75V0H4.25ZM11.5 0.439453V5.75C11.5 6.1635 11.8365 6.5 12.25 6.5H17.5605L11.5 0.439453ZM10.25 9.5C10.664 9.5 11 9.8355 11 10.25V16.75C11 17.1645 10.664 17.5 10.25 17.5C9.836 17.5 9.5 17.1645 9.5 16.75V10.25C9.5 9.8355 9.836 9.5 10.25 9.5ZM13.75 12C14.164 12 14.5 12.3355 14.5 12.75V16.75C14.5 17.1645 14.164 17.5 13.75 17.5C13.336 17.5 13 17.1645 13 16.75V12.75C13 12.3355 13.336 12 13.75 12ZM6.75 13.5C7.164 13.5 7.5 13.8355 7.5 14.25V16.75C7.5 17.1645 7.164 17.5 6.75 17.5C6.336 17.5 6 17.1645 6 16.75V14.25C6 13.8355 6.336 13.5 6.75 13.5Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }

  &.doc {
    .type::before {
      background-color: #e6883b;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.894 0H4.19905C2.99957 0 2 1 2 2.2V17.7C2 19 2.99957 20 4.19905 20H15.794C16.9935 20 17.9931 19 17.9931 17.8V2.2C18.0931 1 17.0935 0 15.894 0ZM14.0722 16H6.92783C6.37113 16 6 15.6 6 15C6 14.4 6.37113 14 6.92783 14H14.0722C14.6289 14 15 14.4 15 15C15 15.6 14.6289 16 14.0722 16ZM14.0722 13H6.92783C6.37113 13 6 12.6 6 12C6 11.4 6.37113 11 6.92783 11H14.0722C14.6289 11 15 11.4 15 12C15 12.6 14.6289 13 14.0722 13ZM14.0722 10H6.92783C6.37113 10 6 9.6 6 9C6 8.4 6.37113 8 6.92783 8H14.0722C14.6289 8 15 8.4 15 9C15 9.6 14.6289 10 14.0722 10ZM13.9474 6H10.0526C9.42105 6 9 5.6 9 5C9 4.4 9.52632 4 10.0526 4H13.9474C14.5789 4 15 4.4 15 5C15 5.6 14.5789 6 13.9474 6Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }

  &.calendar {
    .type::before {
      background-color: #686d72;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.6 20H16.4C18.4 20 20 18.4 20 16.4V6H0V16.4C0 18.4 1.6 20 3.6 20ZM13.1 11H15.9C16.5 11 17 11.5 17 12.1V14.9C17 15.5 16.5 16 15.9 16H13.1C12.5 16 12 15.5 12 14.9V12.1C12 11.5 12.5 11 13.1 11ZM16.4 0H3.6C1.6 0 0 1.6 0 3.6V4H20V3.6C20 1.6 18.4 0 16.4 0Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }

  &:hover {
    filter: brightness(1.02);
  }
}

.content-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--sg-text-primary);
  transition: all 100ms ease-in-out;

  .card-heading {
    display: flex;
    flex-direction: column;

    h4,
    .h4,
    h5,
    .h5 {
      order: 2;
      margin-top: 0;
    }

    img {
      margin-bottom: 16px;
    }
  }

  .card-footer {
    margin-top: 16px;
  }

  .card-body {
    margin-bottom: auto;
  }
}

.nav-card {
  position: relative;
  color: var(--sg-text-primary);
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 100ms ease-in-out;
  min-height: 200px;

  background-image: url("data:image/svg+xml,%3Csvg width='66' height='57' viewBox='0 0 66 57' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 57H60C63.3 57 66 54.3 66 51V12C66 8.7 63.3 6 60 6H31.2L26.1 0.9C25.6 0.3 24.8 0 24 0H6C2.7 0 0 2.7 0 6V42C0 43.7 1.3 45 3 45C4.7 45 6 43.7 6 42V6H22.8L27.9 11.1C28.4 11.7 29.2 12 30 12H60V51H3C1.3 51 0 52.3 0 54C0 55.7 1.3 57 3 57Z' fill='%23666666'/%3E%3Cpath d='M27 18C28.7 18 30 19.3 30 21C30 22.7 28.7 24 27 24H15C13.3 24 12 22.7 12 21C12 19.3 13.3 18 15 18H27Z' fill='%23666666'/%3E%3C/svg%3E%0A");
  background-position: 20px calc(100% - 28px);
  background-repeat: no-repeat;

  .card-heading {
    display: flex;
    flex-direction: column;

    h4,
    .h4,
    h5,
    .h5 {
      order: 2;
      margin-top: 0;
      margin-bottom: 4px;

      &::after {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_866_10418)'%3E%3Cpath d='M16.875 12.5H15.625C15.4592 12.5 15.3003 12.5658 15.1831 12.6831C15.0658 12.8003 15 12.9592 15 13.125V17.5H2.5V5H8.125C8.29076 5 8.44973 4.93415 8.56694 4.81694C8.68415 4.69973 8.75 4.54076 8.75 4.375V3.125C8.75 2.95924 8.68415 2.80027 8.56694 2.68306C8.44973 2.56585 8.29076 2.5 8.125 2.5H1.875C1.37772 2.5 0.900805 2.69754 0.549175 3.04917C0.197544 3.40081 0 3.87772 0 4.375L0 18.125C0 18.6223 0.197544 19.0992 0.549175 19.4508C0.900805 19.8025 1.37772 20 1.875 20H15.625C16.1223 20 16.5992 19.8025 16.9508 19.4508C17.3025 19.0992 17.5 18.6223 17.5 18.125V13.125C17.5 12.9592 17.4342 12.8003 17.3169 12.6831C17.1997 12.5658 17.0408 12.5 16.875 12.5ZM19.0625 0H14.0625C13.2277 0 12.8105 1.01211 13.3984 1.60156L14.7941 2.99727L5.27344 12.5145C5.18604 12.6016 5.11669 12.705 5.06937 12.819C5.02206 12.933 4.9977 13.0551 4.9977 13.1785C4.9977 13.3019 5.02206 13.4241 5.06937 13.538C5.11669 13.652 5.18604 13.7555 5.27344 13.8426L6.15898 14.7266C6.24608 14.814 6.34957 14.8833 6.46353 14.9306C6.57748 14.9779 6.69966 15.0023 6.82305 15.0023C6.94643 15.0023 7.06861 14.9779 7.18256 14.9306C7.29652 14.8833 7.40001 14.814 7.48711 14.7266L17.0031 5.20781L18.3984 6.60156C18.9844 7.1875 20 6.77734 20 5.9375V0.9375C20 0.68886 19.9012 0.450403 19.7254 0.274587C19.5496 0.098772 19.3111 0 19.0625 0Z' fill='white' fill-opacity='0.9'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_866_10418'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-left: 8px;
        margin-bottom: -2px;
      }
    }
  }

  &.internal .card-heading {
    h4,
    .h4,
    h5,
    .h5 {
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.4 10.4C20.5 10.2 20.5 9.9 20.4 9.6C20.3 9.5 20.3 9.4 20.2 9.3L13.2 2.3C12.8 1.9 12.2 1.9 11.8 2.3C11.4 2.7 11.4 3.3 11.8 3.7L17.1 9H1.5C0.9 9 0.5 9.4 0.5 10C0.5 10.6 0.9 11 1.5 11H17.1L11.9 16.2C11.5 16.6 11.5 17.2 11.9 17.6C12.1 17.8 12.4 17.9 12.6 17.9C12.8 17.9 13.1 17.8 13.3 17.6L20.2 10.7C20.3 10.6 20.4 10.5 20.4 10.4Z' fill='white' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &.form {
    background-image: url("data:image/svg+xml,%3Csvg width='64' height='66' viewBox='0 0 64 66' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M57 65.8643H21C19.3 65.8643 18 64.5643 18 62.8643C18 61.1643 19.3 59.8643 21 59.8643H57C58.7 59.8643 60 61.1643 60 62.8643C60 64.5643 58.7 65.8643 57 65.8643ZM9 65.8643H3C1.3 65.8643 0 64.5643 0 62.8643C0 61.1643 1.3 59.8643 3 59.8643H9C10.7 59.8643 12 61.1643 12 62.8643C12 64.5643 10.7 65.8643 9 65.8643ZM57 56.8643H21C19.3 56.8643 18 55.5643 18 53.8643C18 52.1643 19.3 50.8643 21 50.8643H57C58.7 50.8643 60 52.1643 60 53.8643C60 55.5643 58.7 56.8643 57 56.8643ZM9 56.8643H3C1.3 56.8643 0 55.5643 0 53.8643C0 52.1643 1.3 50.8643 3 50.8643H9C10.7 50.8643 12 52.1643 12 53.8643C12 55.5643 10.7 56.8643 9 56.8643ZM21 44.8643C19.7 44.8643 18.6 44.0643 18.2 42.9643C18 42.5643 18 42.2643 18 41.8643C18 41.5643 18 41.3643 18.1 41.0643L21.1 29.0643C21.2 28.5643 21.5 28.0643 21.9 27.6643L33.9 15.6643C35.1 14.4643 37 14.4643 38.1 15.6643C39.3 16.8643 39.3 18.7643 38.1 19.8643L26.7 31.3643L25.1 37.7643L31.5 36.1643L57 10.5643L52.5 5.96429L44.2 14.0643C43 15.2643 41.1 15.2643 40 14.0643C38.8 12.8643 38.8 10.9643 40 9.8643L48.3 1.66429C50.7 -0.635705 54.6 -0.535705 56.9 1.86429L61.3 6.36429C63.7 8.76429 63.7 12.5643 61.4 14.9643L35.1 40.9643C34.7 41.3643 34.2 41.6643 33.7 41.7643L21.7 44.7643C21.5 44.8643 21.2 44.8643 21 44.8643Z' fill='%23666666'/%3E%3C/svg%3E%0A");
  }

  &.page {
    background-image: url("data:image/svg+xml,%3Csvg width='65' height='60' viewBox='0 0 65 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M56 60C54.4 60 53 58.6 53 57V27H35C33.4 27 32 25.6 32 24V6H11V57C11 58.6 9.6 60 8 60C6.4 60 5 58.6 5 57V3C5 1.4 6.4 0 8 0H35C35.4 0 35.8 0.0999998 36.1 0.2C36.5 0.4 36.8 0.6 37.1 0.9L58.1 21.9C58.4 22.2 58.6 22.5 58.8 22.9C58.9 23.2 59 23.6 59 24V57C59 58.6 57.6 60 56 60ZM38 21H48.8L38 10.2V21ZM44 60H20C18.4 60 17 58.6 17 57C17 55.4 18.4 54 20 54H44C45.6 54 47 55.4 47 57C47 58.6 45.6 60 44 60ZM44 45H20C18.4 45 17 43.6 17 42C17 40.4 18.4 39 20 39H44C45.6 39 47 40.4 47 42C47 43.6 45.6 45 44 45Z' fill='%23666666'/%3E%3C/svg%3E%0A");
  }

  &.secure {
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='71' viewBox='0 0 48 71' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 68C48 69.6569 46.6569 71 45 71H9C4.1 71 0 66.9 0 62V30C0 26.7 2.7 24 6 24V18C6 8.1 14.1 0 24 0C33.9 0 42 8.1 42 18V24C45.3 24 48 26.7 48 30V56C48 57.6 46.6 59 45 59C43.4 59 42 57.6 42 56V30H6V62C6 63.6 7.4 65 9 65H45C46.6569 65 48 66.3431 48 68ZM12 24H36V18C36 11.4 30.6 6 24 6C17.4 6 12 11.4 12 18V24ZM24 59C22.4 59 21 57.6 21 56V50C21 48.4 22.4 47 24 47C25.6 47 27 48.4 27 50V56C27 57.6 25.6 59 24 59ZM27 41C27 39.4 25.6 38 24 38C22.4 38 21 39.4 21 41C21 42.6 22.4 44 24 44C25.6 44 27 42.6 27 41Z' fill='%23666666'/%3E%3C/svg%3E%0A");
  }

  .card-body {
    flex-grow: 4;
    color: var(--sg-text-secondary);
    padding-bottom: 80px;
  }

  .card-footer {
    margin-top: 16px;

    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-indent: -2000px;
      border-radius: 20px;
    }
  }

  &:hover {
    filter: brightness(2);
  }
}

.shortcut-card {
  position: relative;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 100ms ease-in-out;
  color: var(--sg-text-primary);

  &:hover {
    filter: brightness(1.02);
  }

  .card-heading {
    display: flex;
    flex-direction: column;

    h4,
    .h4,
    h5,
    .h5 {
      order: 2;
      margin-top: 0;
      margin-bottom: 4px;
    }
  }

  .card-body {
    flex-grow: 4;
    color: var(--sg-text-secondary);
  }

  .card-footer {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;

    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-indent: -2000px;
      border-radius: 20px;
    }
  }
}
