.alert {
  background-color: $white;
  border-radius: 8px;
  border-color: $brand-success;
  padding: 16px 16px 16px 48px;
  position: relative;
  overflow: hidden;
  color: $text-primary;

  &::before {
    content: "";
    position: absolute;
    width: 32px;
    height: 100%;
    top: 0;
    left: 0;
    background: $brand-success
      url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58173 0.5 3.8147e-06 4.08172 3.8147e-06 8.5C3.8147e-06 12.9183 3.58173 16.5 8 16.5ZM9 7.5V13.5H7V7.5H9ZM9.5 4.5C9.5 5.32843 8.82843 6 8 6C7.17158 6 6.5 5.32843 6.5 4.5C6.5 3.67157 7.17158 3 8 3C8.82843 3 9.5 3.67157 9.5 4.5Z' fill='white'/%3E%3C/svg%3E%0A")
      no-repeat center center;
  }

  .alert-link {
    color: inherit;
    font-weight: inherit;
  }
}

.alert-success {
  border-color: $brand-success;
  &::before {
    @include semantic-icons("success");
    background-color: $brand-success;
  }
}

.alert-info {
  border-color: $brand-info;
  &::before {
    @include semantic-icons("info");
    background-color: $brand-info;
  }
}

.alert-warning {
  border-color: $brand-warning;
  &::before {
    @include semantic-icons("warning");
    background-color: $brand-warning;
  }
}

.alert-danger {
  border-color: $brand-danger;
  &::before {
    @include semantic-icons("error");
    background-color: $brand-danger;
  }
}

.alert-dismissible .close,
.alert-dismissable .close {
  right: 0;
}
