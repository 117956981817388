.well {
  color: $text-primary;
  padding: 20px;
  margin-bottom: 24px;
  background-color: $grey50;
  border-color: $grey100;
  border-radius: 12px;
}

.well-lg {
  padding: 24px;
  border-radius: 16px;
}

.well-sm {
  padding: 16px;
  border-radius: 8px;
}
