.popover {
  color: $text-primary;
  font-family: var(--sg-font-body);
  line-height: 1.6;
  font-size: 14px;
  border: none;
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.2));
  padding: 0;
  border-radius: 8px;

  h3,
  .h3 {
    font-family: var(--sg-font-body);
    font-weight: 500;
  }

  &.left > .arrow {
    border-left-color: transparent;
  }

  &.right > .arrow {
    border-right-color: transparent;
  }

  &.top > .arrow {
    border-top-color: transparent;
  }

  &.bottom > .arrow {
    border-bottom-color: transparent;

    &::after {
      border-bottom-color: $grey50;
    }
  }
}

.popover-title {
  padding: 12px 20px;
  background-color: $grey50;
}

.popover-content {
  padding: 12px 20px;
}
