.btn {
  color: var(--sg-stroke-primary);
  font-weight: 500;
  padding: 13px 16px;
  font-size: 16px;
  line-height: 100%;
  border-width: 2px;
  border-radius: 24px;
  height: 48px;
  position: relative;
  transition: all 0.1s ease-in-out;
  overflow: hidden;

  &:active,
  &.active {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25) inset;
  }

  &[disabled],
  &.disabled {
    opacity: 0.3;
  }

  &.active.focus,
  &.active:focus,
  &:active.focus,
  &:active:focus,
  &:focus,
  &.focus {
    outline: none;
  }

  &.active.focus-visible,
  &.active:focus-visible,
  &:active:focus-visible,
  &:focus-visible {
    @include focus-state();
  }
}

.btn-default {
  color: var(--sg-text-primary);
  background-color: transparent;
  border-color: var(--sg-border-primary);
  padding: 14px 16px;

  &:hover,
  &:active,
  &:focus,
  &.focus,
  &.active,
  &:active:focus,
  &.active:hover,
  &:active.focus,
  &.active.focus,
  &[disabled]:hover {
    color: var(--sg-stroke-primary);
    background-color: transparent;
    border-color: var(--sg-stroke-primary);
  }

  &:hover,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active.focus,
  &.active.focus {
    background-color: var(--sg-hover);
  }

  .open > &.dropdown-toggle,
  .open > &.dropdown-toggle.focus,
  .open > &.dropdown-toggle:focus,
  .open > &.dropdown-toggle:hover {
    color: var(--sg-stroke-primary);
    background-color: var(--sg-hover);
    border-color: var(--sg-stroke-primary);
  }
}

.btn-primary {
  color: var(--sg-text-primary-rev);
  background-color: var(--sg-stroke-primary);
  border-color: transparent;

  &:hover,
  &:active,
  &:focus,
  &.focus,
  &.active,
  &:active:hover,
  &:active:focus,
  &.active:hover,
  &:active.focus,
  &.active.focus,
  &[disabled]:hover {
    color: var(--sg-stroke-primary-rev);
    background-color: var(--sg-hover-rev);
    border-color: transparent;
  }

  &:hover,
  &:active,
  &.active,
  &:active:focus,
  &:active.focus,
  &.active:focus,
  &.active.focus {
    color: var(--sg-stroke-primary-rev);
    background-color: var(--sg-hover-rev);
  }

  &.active {
    box-shadow: 0 0 6px 0 #000000 inset;
  }

  .open > &.dropdown-toggle,
  .open > &.dropdown-toggle.focus,
  .open > &.dropdown-toggle:focus,
  .open > &.dropdown-toggle:hover {
    color: var(--sg-stroke-primary-rev);
    background-color: var(--sg-hover-rev);
    border-color: transparent;
  }
}

.btn-success {
  background-color: $brand-success;
  border-color: transparent;
  color: $black;

  &:hover,
  &:active,
  &:focus,
  &.active,
  &:active:focus,
  &.active:hover,
  &.active.focus,
  &:active.focus,
  &[disabled]:hover {
    color: $black;
    background-color: $brand-success;
    border-color: transparent;
  }

  &:hover,
  &:active,
  &.active,
  &:active:focus,
  &.active.focus,
  &:active.focus,
  &.active:focus {
    background-color: $brand-success100;
  }

  .open > &.dropdown-toggle,
  .open > &.dropdown-toggle.focus,
  .open > &.dropdown-toggle:focus,
  .open > &.dropdown-toggle:hover {
    color: $black;
    background-color: $brand-success100;
    border-color: transparent;
  }
}

.btn-info {
  color: $white;
  background-color: $brand-info;
  border-color: transparent;

  &:hover,
  &:active,
  &:focus,
  &.active,
  &:active:focus,
  &.active:hover,
  &.active.focus,
  &:active.focus,
  &[disabled]:hover {
    color: $white;
    background-color: $brand-info100;
    border-color: transparent;
  }

  &:hover,
  &:active,
  &.active,
  &:active:focus,
  &.active.focus,
  &:active.focus,
  &.active:focus {
    background-color: $brand-info100;
  }

  .open > &.dropdown-toggle,
  .open > &.dropdown-toggle.focus,
  .open > &.dropdown-toggle:focus,
  .open > &.dropdown-toggle:hover {
    color: $white;
    background-color: $brand-info100;
    border-color: transparent;
  }
}

.btn-warning {
  background-color: $brand-warning;
  border-color: transparent;
  color: $black;

  &:hover,
  &:active,
  &:focus,
  &.active,
  &:active:focus,
  &.active:hover,
  &.active.focus,
  &:active.focus,
  &[disabled]:hover {
    color: $black;
    background-color: $brand-warning100;
    border-color: transparent;
  }

  &:hover,
  &:active,
  &.active,
  &:active:focus,
  &.active.focus,
  &:active.focus,
  &.active:focus {
    background-color: $brand-warning100;
  }

  .open > &.dropdown-toggle,
  .open > &.dropdown-toggle.focus,
  .open > &.dropdown-toggle:focus,
  .open > &.dropdown-toggle:hover {
    color: $black;
    background-color: $brand-warning100;
    border-color: transparent;
  }
}

.btn-danger {
  color: $white;
  background-color: $brand-danger;
  border-color: transparent;

  &:hover,
  &:active,
  &:focus,
  &.active,
  &:active:focus,
  &.active:hover,
  &.active.focus,
  &[disabled]:hover {
    color: $white;
    background-color: $brand-danger100;
    border-color: transparent;
  }

  &:hover,
  &:active,
  &.active,
  &:active:focus,
  &.active.focus,
  &.active:focus {
    background-color: $brand-danger100;
  }

  .open > &.dropdown-toggle,
  .open > &.dropdown-toggle.focus,
  .open > &.dropdown-toggle:focus,
  .open > &.dropdown-toggle:hover {
    color: $white;
    background-color: $brand-danger100;
    border-color: transparent;
  }
}

.btn-link {
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  color: var(--sg-text-primary);
  position: relative;
  border-radius: 4px !important;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    bottom: 7px;
    left: 0;
    width: 300%;
    height: 2px;
    background: linear-gradient(
      90deg,
      var(--sg-stroke-primary) 0%,
      var(--sg-stroke-primary) 33.3333%,
      $brilliant-green 66.6667%,
      $brilliant-green 100%
    );
    transition: all 0.2s ease-in-out;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: unset;

    &::after {
      left: -200%;
    }
  }

  &:active,
  &.active {
    background-color: var(--sg-active-opacity);
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

a.btn {
  text-decoration: none;
}

.btn + .btn:not(.btn-block) {
  margin-left: 10px;
}

.btn-block + .btn-block {
  margin-top: 10px;
}

.btn-group-lg > .btn,
.btn-lg {
  font-size: 20px;
  height: 60px;
  border-radius: 30px;
  padding: 18px 20px;

  &.btn-link::after {
    bottom: 9px;
  }
}

.btn-group-sm > .btn,
.btn-sm {
  font-size: 14px;
  height: 32px;
  padding: 7px 12px;

  &.btn-link::after {
    bottom: 4px;
  }
}

.btn-group-xs > .btn,
.btn-xs {
  font-size: 12px;
  height: 28px;
  padding: 6px 12px;

  &.btn-link::after {
    bottom: 3px;
  }
}
