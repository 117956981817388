.pagination {
  margin: 24px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 8px;

  > li {
    > a,
    > span {
      line-height: 22px;
      color: var(--sg-text-primary);
      background-color: transparent;
      border: 0;
      padding: 5px;
      min-width: 32px;
      text-align: center;
      border-radius: 16px;

      &:focus,
      &:hover {
        color: var(--sg-text-primary);
        background-color: var(--sg-hover-opacity);
      }
    }
  }

  > li:last-child > a,
  > li:last-child > span {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  > li:first-child > a,
  > li:first-child > span {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  > .disabled {
    > a,
    > a:focus,
    > a:hover,
    > span,
    > span:focus,
    > span:hover {
      color: var(--sg-text-disabled);
      background-color: transparent;
    }
  }

  > .active {
    > a,
    > a:focus,
    > a:hover,
    > span,
    > span:focus,
    > span:hover {
      color: var(--sg-brand-accent-rev);
      background-color: var(--sg-stroke-primary);
    }
  }
}

.pagination-lg > li,
.pagination-lg > li {
  > a,
  > span {
    line-height: 28px;
    font-size: 20px;
    min-width: 48px;
    border-radius: 24px;
    padding: 10px;
  }

  &:first-child > a,
  &:first-child > span {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
  }

  &:last-child > a,
  &:last-child > span {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
  }
}

.pagination-sm > li,
.pagination-sm > li {
  > a,
  > span {
    line-height: 18px;
    font-size: 14px;
    min-width: 28px;
    border-radius: 14px;
  }

  &:first-child > a,
  &:first-child > span {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }

  &:last-child > a,
  &:last-child > span {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }
}

.pager {
  margin: 24px 0;

  li > a,
  li > span {
    padding: 8px 0;
    border: 0;
    border-radius: 8px;
    background-color: transparent;
    color: var(--sg-text-primary);
    position: relative;
    overflow: hidden;
    font-weight: 500;
    text-decoration: none;

    &::after {
      content: "";
      position: absolute;
      bottom: 7px;
      left: 0;
      width: 300%;
      height: 2px;
      background: linear-gradient(
        90deg,
        #000000 0%,
        #000000 33.3333%,
        #00d639 66.6667%,
        #00d639 100%
      );
      transition: all 0.2s ease-in-out;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      color: unset;

      &::after {
        left: -200%;
      }
    }

    &:active,
    &.active {
      background-color: var(--sg-active-opacity);
    }
  }

  .disabled {
    > a,
    > a:focus,
    > a:hover,
    > span,
    > span:focus,
    > span:hover {
      color: var(--sg-text-disabled);
      background-color: transparent;

      &::after {
        background: var(--sg-text-disabled);
      }
    }
  }
}
