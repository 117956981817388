.navbar-brand {
  display: grid;
  padding: 12px 16px;
  margin: 5px 0;

  img {
    align-self: center;
  }
}

.navbar-default {
  background-color: $grey25;
  border-color: $grey100;

  .navbar-nav > li > a {
    color: $text-primary;
  }

  .navbar-nav > .active {
    > a,
    > a:focus,
    > a:hover {
      color: $text-primary-rev;
      background-color: $brand-primary;
      border-radius: 8px;
    }
  }

  .navbar-text {
    color: $text-primary;
    margin-top: 16px;
    margin-bttom: 16px;
  }

  .navbar-nav > li > a:hover,
  .navbar-nav > .open > a,
  .navbar-nav > .open > a:focus,
  .navbar-nav > .open > a:hover {
    color: $text-primary;
    background-color: $black10;
    border-radius: 8px;
  }

}

.navbar-inverse {
  background-color: $grey900;
  border-color: transparent;

  .navbar-nav > li > a {
    border-radius: 8px;
    color: $text-primary-rev;
  }

  .navbar-nav > .active > a,
  .navbar-nav > .active > a:focus,
  .navbar-nav > .active > a:hover {
    color: $text-primary-rev;
    background-color: $white20;
  }

  .navbar-nav > li > a:hover {
    color: $text-primary-rev;
    background-color: $white20;
  }
}
