.tooltip {
  font-family: var(--sg-font-body);
  font-size: 14px;
  line-height: 1.6;
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.2));

  &.left {
    padding: 0 8px;

    .tooltip-arrow {
      border-left-color: $grey50;
      margin-top: -8px;
      border-width: 8px 0 8px 8px;
    }
  }

  &.top {
    padding: 8px 0;

    .tooltip-arrow {
      border-top-color: $grey50;
      margin-left: -8px;
      border-width: 8px 8px 0;
    }
  }

  &.right {
    padding: 0 8px;

    .tooltip-arrow {
      border-right-color: $grey50;
      margin-top: -8px;
      border-width: 8px 8px 8px 0;
    }
  }

  &.bottom {
    padding: 8px 0;

    .tooltip-arrow {
      border-bottom-color: $grey50;
      margin-left: -8px;
      border-width: 0 8px 8px;
    }
  }
}

.tooltip-inner {
  padding: 8px 16px;
  color: $text-primary;
  background-color: $grey50;
  border-radius: 8px;
}
