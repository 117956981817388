.label {
  font-family: var(--sg-font-body);
  @include subtitle("sm");
  font-size: 0.75em;
  border-radius: 1em;
  background-color: var(--sg-hover-opacity);
  color: var(--sg-text-primary);
  padding: 0.25em 0.75em;
}

.label-primary {
  background-color: var(--sg-stroke-primary);
  color: var(--sg-text-primary-rev);
}

.label-success {
  background-color: $brand-success;
  color: $black;
}

.label-info {
  background-color: $brand-info;
  color: $white;
}

.label-warning {
  background-color: $brand-warning;
  color: $black;
}

.label-danger {
  background-color: $brand-danger;
  color: $white;
}
