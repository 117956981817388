.nav-tabs {
  border-bottom-color: transparent;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-color: var(--sg-hover-opacity);
    height: 4px;
    bottom: 0;
    width: 100%;
    border-radius: 2px;
  }

  > li {
    margin: 0 8px 0 0;

    > a {
      padding: 13px 16px;
      font-weight: 500;
      border: 0;
      line-height: 1.4;
      margin-right: 0;
      color: var(--sg-text-secondary);
      border-radius: 8px 8px 0 0;
    }
  }

  > li.active {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      background-color: var(--sg-brand-accent);
      height: 4px;
      bottom: 0;
      width: 100%;
      border-radius: 2px;
    }

    > a,
    > a:focus,
    > a:hover {
      color: var(--sg-text-primary);
      background-color: transparent;
      border: 0;
    }
  }

  &.nav-justified > li > a {
    border-radius: 8px;

    @include media-breakpoint-up(sm) {
      border-radius: 8px 8px 0 0;
      border-bottom: 0;
    }
  }

  &.nav-justified > .active > a,
  &.nav-justified > .active > a:focus,
  &.nav-justified > .active > a:hover {
    border: 0;
  }
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  color: var(--sg-text-primary-rev);
  background-color: var(--sg-stroke-primary);
}

.nav-pills {
  > li > a {
    border-radius: 8px;
    text-decoration: none;
  }
}

.nav > li > a {
  padding: 16px 16px;
  line-height: 1;
  font-weight: 500;
  color: var(--sg-text-primary);
  text-decoration: none;

  &:focus,
  &:hover {
    background-color: var(--sg-hover-opacity);
  }
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: var(--sg-hover);
  border-color: transparent;
}
