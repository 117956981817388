.panel {
  background-color: var(--sg-stroke-primary-rev);
  margin-bottom: 24px;
  box-shadow: none;
  border-radius: 8px;
}

.panel-default {
  border-color: var(--sg-border-subtle);

  > .panel-heading {
    border-color: var(--sg-border-subtle);
    color: var(--sg-text-primary);
    background-color: var(--sg-active-opacity);
  }
}

.panel-body {
  padding: 16px;
}

.panel-heading {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 12px 16px;
}

.panel-primary {
  border-color: var(--sg-brand-primary-emphasis);

  > .panel-heading {
    background-color: var(--sg-brand-primary-subtle);
    border-color: var(--sg-brand-primary-emphasis);
    color: var(--sg-brand-primary-emphasis);
  }
}

.panel-success {
  border-color: var(--sg-brand-success-emphasis);

  > .panel-heading {
    background-color: var(--sg-brand-success-subtle);
    border-color: var(--sg-brand-success-emphasis);
    color: var(--sg-brand-success-emphasis);
  }
}

.panel-info {
  border-color: var(--sg-brand-info-emphasis);

  > .panel-heading {
    background-color: var(--sg-brand-info-subtle);
    border-color: var(--sg-brand-info-emphasis);
    color: var(--sg-brand-info-emphasis);
  }
}

.panel-warning {
  border-color: var(--sg-brand-warning-emphasis);

  > .panel-heading {
    background-color: var(--sg-brand-warning-subtle);
    border-color: var(--sg-brand-warning-emphasis);
    color: var(--sg-brand-warning-emphasis);
  }
}

.panel-danger {
  border-color: var(--sg-brand-danger-emphasis);

  > .panel-heading {
    background-color: var(--sg-brand-danger-subtle);
    border-color: var(--sg-brand-danger-emphasis);
    color: var(--sg-brand-danger-emphasis);
  }
}

.panel-footer {
  padding: 12px 16px;
  color: var(--sg-text-primary);
  background-color: var(--sg-active-opacity);
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  border-top-color: var(--sg-border-subtle);
}
