.breadcrumb {
  font-size: 14px;
  background-color: transparent;
  border-radius: 0;
  padding: 8px 16px;

  > li + li::before {
    content: "";
    background: no-repeat transparent
      url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.05 10C2.9 10 2.8 9.95 2.7 9.85C2.5 9.65 2.5 9.35 2.7 9.15L7 5.05L2.65 0.85C2.45 0.65 2.45 0.35 2.65 0.15C2.85 -0.05 3.15 -0.05 3.35 0.15L8.1 4.65C8.15 4.75 8.25 4.9 8.25 5C8.25 5.1 8.2 5.25 8.1 5.35L3.4 9.85C3.3 9.95 3.15 10 3.05 10Z' fill='black' fill-opacity='0.55'/%3E%3C/svg%3E%0A")
      center center;
    padding: 0 16px 0 4px;
    margin-right: 4px;

    [data-bs-theme = "dark"] & {
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.05 10C2.9 10 2.8 9.95 2.7 9.85C2.5 9.65 2.5 9.35 2.7 9.15L7 5.05L2.65 0.85C2.45 0.65 2.45 0.35 2.65 0.15C2.85 -0.05 3.15 -0.05 3.35 0.15L8.1 4.65C8.15 4.75 8.25 4.9 8.25 5C8.25 5.1 8.2 5.25 8.1 5.35L3.4 9.85C3.3 9.95 3.15 10 3.05 10Z' fill='white' fill-opacity='0.55'/%3E%3C/svg%3E%0A");
    }
  }
}

.breadcrumb > .active {
  color: var(--sg-text-primary);
}

.breadcrumb a {
  color: var(--sg-text-primary);
  text-decoration: underline;

  &:hover {
    color: var(--sg-link);
  }

  &:active {
    color: var(--sg-jade600);
  }
}
