.dropdown-menu {
  border-radius: 12px;
  margin-top: 17px;
  font-size: 16px;
  border: 0;
  padding: 8px 0;
  @include shadow-depth-2;

  &::before {
    content: "";
    width: 22px;
    height: 22px;
    border: 11px solid $white;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    position: absolute;
    top: -22px;
    left: 40px;
  }

  > li {
    margin-top: 0;
    margin-bottom: 0;
  }

  > li > a {
    line-height: 1.4;
    font-weight: 500;
    padding: 12px 16px;
    text-decoration: none;
    color: $text-primary;

    &:hover {
      background-color: $black10;
    }
  }

  .divider {
    margin: 4px 16px;
    background-color: $grey200;
  }
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  margin-bottom: 17px;

  &::before {
    border-top-color: $white;
    border-bottom-color: transparent;
    top: auto;
    bottom: -22px;
  }
}

.caret {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.0650264 0.290381C-0.0542695 0.46461 0.00537848 0.696915 0.124674 0.871143L5.55264 7.78221C5.79123 8.0726 6.20877 8.0726 6.44736 7.78221L11.8753 0.871143C11.9946 0.696915 12.0543 0.46461 11.935 0.290381C11.756 0.116152 11.5771 0 11.3981 0H0.54221C0.363266 0 0.184322 0.116152 0.0650264 0.290381Z' fill='black'/%3E%3C/svg%3E%0A");
  width: 12px;
  height: 8px;
  border: 0 !important;
  margin: 0 4px !important;

  [data-bs-theme = "dark"] & {
    filter: invert(100%);
    // background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.0650264 0.290381C-0.0542695 0.46461 0.00537848 0.696915 0.124674 0.871143L5.55264 7.78221C5.79123 8.0726 6.20877 8.0726 6.44736 7.78221L11.8753 0.871143C11.9946 0.696915 12.0543 0.46461 11.935 0.290381C11.756 0.116152 11.5771 0 11.3981 0H0.54221C0.363266 0 0.184322 0.116152 0.0650264 0.290381Z' fill='white'/%3E%3C/svg%3E%0A");
  }
}

.dropup .caret {
  transform: rotate(180deg);
  margin-bottom: 4px !important;
}

.dropdown-header {
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:focus,
.dropdown-menu > .disabled > a:hover {
  color: $text-disabled;
}

.dropdown-menu-right {
  &::before {
    left: auto;
    right: 40px;
  }
}

.navbar-right .dropdown-menu::before {
  left: auto;
  right: 40px;
}
