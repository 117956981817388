.img-rounded {
  border-radius: 32px;
}

.img-thumbnail {
  border-radius: 32px;
  padding: 8px;
  line-height: 1.6;
  background-color: transparent;
  border-color: var(--sg-border-subtle);
}
