.hero-banner {
  color: var(--sg-text-primary);
  @include clearfix;

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3 {
    margin-top: 0;
  }

  @include media-breakpoint-down(sm) {
    > .flexbox.column-2 {
      gap: 0;
    }
  }
}
