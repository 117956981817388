.panel-group {
  .panel {
    border: none;

    + .panel {
      margin-top: -1px;
    }
  }

  .panel-title {
    font-size: 20px;
    font-weight: 500;

    a {
      text-decoration: none;
      display: block;
      padding: 24px;
      position: relative;

      &:hover,
      &:focus {
        background-color: var(--sg-hover-opacity);
        color: var(--sg-text-primary);
      }

      &::before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        right: 16px;
        top: 50%;
        margin-top: -10px;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 5C18.7 5 18.5 5.1 18.3 5.3L10 13.6L1.7 5.3C1.3 4.9 0.7 4.9 0.3 5.3C-0.1 5.7 -0.1 6.3 0.3 6.7L9.3 15.7C9.7 16.1 10.3 16.1 10.7 15.7L19.7 6.7C20.1 6.3 20.1 5.7 19.7 5.3C19.5 5.1 19.3 5 19 5Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
        transition: all 200ms ease-in-out;

        [data-bs-theme = "dark"] & {
          filter: invert(100%);
        }
      }

      &.collapsed::before {
        transform: rotate(180deg);
      }
    }
  }

  .panel-heading {
    background-color: transparent;
    padding: 0;
    border: 0;
    border-top: 1px solid $grey200;
    border-bottom: 1px solid $grey200;
    border-radius: 0;
  }

  .panel-body {
    padding: 24px;
    border-top: 0 !important;
  }

  .list-group-item {
    padding: 12px 24px;
    background-color: transparent;
  }

  .panel-footer {
    padding: 12px 24px;
  }
}

.panel .list-group-item {
  background-color: transparent;
}
