.thumbnail {
  padding: 0;
  margin-bottom: 24px;
  border-color: var(--sg-border-subtle);
  border-radius: 24px;
  overflow: hidden;
  line-height: 1.6;
  background-color: $white10;

  .caption {
    padding: 24px;
    color: inherit;

    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5 {
      &:first-child {
        margin-top: 0;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

a.thumbnail.active,
a.thumbnail:focus,
a.thumbnail:hover {
  border-color: var(--sg-brand-accent);
}
