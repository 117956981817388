/* 4 column panel */

.flexbox {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 30px;

  .flex-item {
    flex-basis: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    flex-wrap: wrap;

    .flex-item {
      flex-basis: calc(50% - 15px);

      > div {
        height: 100%;
      }
    }

    &.column-2 {
      flex-direction: column;
      flex-wrap: nowrap;

      .flex-item {
        flex-basis: 100%;
      }
    }
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    flex-wrap: wrap;

    &.column-4 .flex-item {
      flex-basis: calc(25% - 22.5px);
    }

    &.column-3 .flex-item {
      flex-basis: calc(33.3333% - 20px);
    }

    &.column-2 {
      flex-direction: row;
      flex-wrap: wrap;

      .flex-item {
        flex-basis: calc(50% - 15px);

        > div,
        > img.h-100 {
          height: 100%;
        }
      }
    }

    &.column-2 .flex-item {
      flex-basis: calc(50% - 15px);
    }

    &.column-2.wide-left .flex-item {
      &:nth-child(2n - 1) {
        flex-basis: calc(58.3333% - 30px);
      }

      &:nth-child(2n) {
        flex-basis: calc(41.6667% - 30px);
      }
    }

    &.column-2.wide-right .flex-item {
      &:nth-child(2n) {
        flex-basis: calc(58.3333% - 30px);
      }

      &:nth-child(2n - 1) {
        flex-basis: calc(41.6667% - 30px);
      }
    }

    &.column-2.wider-left .flex-item {
      &:nth-child(2n - 1) {
        flex-basis: calc(66.6667% - 30px);
      }

      &:nth-child(2n) {
        flex-basis: calc(33.3333% - 30px);
      }
    }

    &.column-2.wider-right .flex-item {
      &:nth-child(2n) {
        flex-basis: calc(66.6667% - 30px);
      }

      &:nth-child(2n - 1) {
        flex-basis: calc(33.3333% - 30px);
      }
    }
  }

  &.with-divider {
    row-gap: 60px;

    .flex-item {
      @include media-breakpoint-up(sm) {
        border-right: 1px dashed $grey100;
        padding-right: 30px;

        &:last-child {
          border-right-color: transparent;
        }
      }
    }

    @include media-breakpoint-only(sm) {
      .flex-item:nth-child(2n - 1) {
        flex-basis: 50%;
      }

      .flex-item:nth-child(2n) {
        flex-basis: calc(50% - 30px);
        border-right: transparent;
        padding-right: 0;
      }
    }

    @include media-breakpoint-up(md) {
      &.column-4 .flex-item:nth-child(4n - 3),
      &.column-4 .flex-item:nth-child(4n - 2),
      &.column-4 .flex-item:nth-child(4n - 1) {
        flex-basis: calc(((100% - 180px) * 0.25) + 30px);
      }

      &.column-4 .flex-item:nth-child(4n) {
        flex-basis: calc(25% - 45px);
        border-right: transparent;
        padding-right: 0;
      }

      &.column-3 .flex-item:nth-child(3n-2),
      &.column-3 .flex-item:nth-child(3n-1) {
        flex-basis: calc(((100% - 180px) * 0.333333) + 30px);
      }

      &.column-3 .flex-item:nth-child(3n) {
        flex-basis: calc(33.3333% - 40px);
        border-right: transparent;
        padding-right: 0;
      }
    }
  }
}
